import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Digesteur } from 'shared-ration/models/digesteur';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DigesteurService {

  private readonly _headers: any[];
  private readonly organisationId;
  private uriPath = 'digesteurs';

  constructor(
      private http: HttpClient
  ) {
    this._headers = [
      { nom: 'nom', label: 'Nom' },
      { nom: 'code', label: 'Code' },
      { nom: 'volumeUtile', label: 'Volume utile' },
      { nom: 'description', label: 'Description' },
    ];
    this.organisationId = JSON.parse(sessionStorage.getItem('organisation')).id;
  }

  headers() {
    return this._headers;
  }

  getDigesteursBySite(siteId: string) {
    return this.http.get(environment.api + `/organisations/${this.organisationId}/sites/${siteId}/${this.uriPath}/`);
  }

  getAllDigesteur(siteId: string) {
    return this.http.get(environment.api + `/organisations/${this.organisationId}/sites/${siteId}/${this.uriPath}/`);
  }

  getDigesteur(siteId: string, digesteurId: string) {
    return this.http.get(environment.api + `/organisations/${this.organisationId}/sites/${siteId}/${this.uriPath}/${digesteurId}`);
  }

  createDigesteur(siteId: string, digesteur: Digesteur) {
    return this.http.post(environment.api + `/organisations/${this.organisationId}/sites/${siteId}/${this.uriPath}/`, digesteur, {responseType: 'text'});
  }

  updateDigesteur(siteId: string, digesteur: Digesteur, digesteurId: string) {
    return this.http.put(environment.api + `/organisations/${this.organisationId}/sites/${siteId}/${this.uriPath}/${digesteurId}`, digesteur);
  }

  deleteDigesteur(siteId: string, digesteurId: string) {
    return this.http.delete(environment.api + `/organisations/${this.organisationId}/sites/${siteId}/${this.uriPath}/${digesteurId}`);
  }

  checkCode(siteId: string, code: string){
    return this.http.get(environment.api + `/organisations/${this.organisationId}/sites/${siteId}/${this.uriPath}/code/${code}`).toPromise();
  }

  isValid(digesteur: Digesteur): boolean {
    return !!(digesteur.nom);
  }
}
