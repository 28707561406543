import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { defer } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ParametresService {

	private parametres;

	constructor() {
		this.onLoad().subscribe(parametres => {
			const sorted = {};
			_.forEach(parametres, (value, key) => {
				if (Array.isArray(value) && value.length && (typeof value[0]) == 'string' && this.keyExluded(key))
					value.sort();
				else if (key === 'codesDechets') {
					value = _.cloneDeep(_.sortBy(value, [function (v) { return v[0].toLocaleLowerCase(); }]));
				}
				sorted[key] = value;
			});
			this.parametres = sorted;
		});
	}

	private keyExluded(key: string): boolean{
		return !_.includes(['headersExportCompta','typeValorisationDigestat'], key);
	}


	onLoad() {
		return this.asyncData<any>({
			'utilisationPrincipale': this.utilisationPrincipale,
			'typeValorisationDigestat': this.typeValorisationDigestat,
			'typeValorisationIntrant': this.typeValorisationIntrant,
			'typeValorisationDechet': this.typeValorisationDechet,
			'typeValorisationAutre': this.typeValorisationAutre,
			'typeProduit': this.typeProduit,
			'documentsAEmettre': this.documentsAEmettre,
			'sousProduitAnimaux': this.sousProduitAnimaux,
			'consistances': this.consistances,
			'categoriesMatiere': this.categoriesMatiere,
			'listGroupCategoriesMatiere': this.listGroupCategoriesMatiere,
			'modesDeLivraison': this.modesDeLivraison,
			'conditionnements': this.conditionnements,
			'codesDechets': this.codesDechets,
			'documentAEmettre': this.documentsAEmettre,
			'pays': this.pays,
			'modules': this.modules,
			'blocs': this.blocs,
			'blocLabels': this.blocLabels,
			'superBlocs': this.superBlocs,
			'routes': this.routes,
			'modulesPerimetreRestreint': this.modulesPerimetreRestreint,
			'typeTiers': this.typeTiers,
			'typeFacturation': this.typeFacturation,
			'typeActivite': this.typeActivite,
			'typeDigestat': this.typeDigestat,
			'statusReception': this.statusReception,
			'statusReceptionFacturation': this.statusReceptionFacturation,
			'statutExport': this.statutExport,
			'units': this.units,
			'analyseFieldLabels': this.analyseFieldLabels,
			'analyseFieldLabelsShort': this.analyseFieldLabelsShort,
			'typesAnalyseDigestat': this.typesAnalyseDigestat,
			'periodesFacturation': this.periodesFacturation,
			'modesPaiement': this.modesPaiement,
			'conditionsPaiement': this.conditionsPaiement,
			'devises': this.devises,
			'tva': this.tva,
			'tauxTva': this.tauxTva,
			'categorieDigestat': this.categorieDigestat,
			'statutsContrat': this.statutsContrat,
			'statutsPiece': this.statutsPiece,
			'numComptePCGArticle': this.numComptePCGArticle,
			'indexationArticle': this.indexationArticle,
			'quantitePrevueArticle': this.quantitePrevueArticle,
			'statutFactureClient': this.statutFactureClient,
			'statutFactureClientExport': this.statutFactureClientExport,
			'statutFactureFournisseur': this.statutFactureFournisseur,
			'statutFactureFournisseurExport': this.statutFactureFournisseurExport,
			'articlesContratClient': this.articlesContratClient,
			'articlesContratFournisseur': this.articlesContratFournisseur,
			'refIndex': this.refIndex,
			'typeSaisie': this.typeSaisie,
			'indexation': this.indexation,
			'nombreIndex': this.nombreIndex,
			'actualisationIndex': this.actualisationIndex,
			'articleUnites': this.articleUnites,
			'envoiFacture': this.envoiFacture,
			'consistancesGisement': this.consistancesGisement,
			'exportDataFileType': this.exportDataFileType,
			'expeditionDestinationType': this.expeditionDestinationType,
			'indicateursRation': this.indicateursRation,
			'receptionAttachmentTypes': this.receptionAttachmentTypes,
			'expeditionAttachmentTypes': this.expeditionAttachmentTypes,
			'contratClientAttachmentTypes': this.contratClientAttachmentTypes,
			'contratFournisseurAttachmentTypes': this.contratFournisseurAttachmentTypes,
			'gisementAttachmentTypes': this.gisementAttachmentTypes,
			'tiersAttachmentTypes': this.tiersAttachmentTypes,
			'analyseIntrantAttachmentTypes': this.analyseIntrantAttachmentTypes,
			'ticketAttachmentTypes': this.ticketAttachmentTypes,
			'interventionAttachmentTypes': this.interventionAttachmentTypes,
			'contratMaintenanceAttachmentTypes': this.contratMaintenanceAttachmentTypes,
			'headersExportCompta': this.headersExportCompta,
			"unitesFamille": this.unitesFamille,
			"nomFamille": this.nomFamille,
			"nomBaseFamille": this.nomBaseFamille,
			"saisieValeursDefaut": this.saisieValeursDefaut,
			"typesGisement": this.typesGisement,
			"durabilitesGisement": this.durabilitesGisement,
			"certificationsGisement": this.certificationsGisement,
			"localisationDigestat": this.localisationDigestat,
			"categorieArticleContratClient": this.categorieArticleContratClient,
			"categorieArticleContratFournisseur": this.categorieArticleContratFournisseur,
			"uniteFonctionnelle": this.uniteFonctionnelle,
			"clauseQualitativeArticle": this.clauseQualitativeArticle,
			"typeTiersArticle": this.typeTiersArticle,
			"methodCalculCPC": this.methodCalculCPC,
			"imputationAnalytique": this.imputationAnalytique,
			"typeTicket": this.typeTicket,
			"typeIndispo": this.typeIndispo,
			"moyenNotification": this.moyenNotification,
			"criticiteTicket": this.criticiteTicket,
			"statutTicket": this.statutTicket,
			"interventionProblemeResolu": this.interventionProblemeResolu,
			"typeVariables": this.typeVariables,
			'affichageTableauParDefaut': this.affichageTableauParDefaut,
			'affichageGraphiqueParDefaut': this.affichageGraphiqueParDefaut,
			"repetitionList": this.repetitionList,
			"typesEngagementContrat": this.typeContrat,
			"typesComptePCG": this.typesComptePCG,
			"analyseFileTypes": this.analyseFileTypes,
			"typesAnalyse": this.typesAnalyse,
			"updateCpcList": this.updateCpcList,
			"updateCpcGisementList": this.updateCpcGisementList,
			"updateCpcExpeditionList": this.updateCpcExpeditionList,
			"updateFacturationList": this.updateFacturationList,
			"typeAnalyse": this.typeAnalyse,
			"optionsSwitch": this.optionsSwitch,
			"ceLabel": this.ceLabel,
			"ceUnit": this.ceUnit,
			"clientAttachmentTypes": this.clientAttachmentTypes,
			"fournisseurAttachmentTypes": this.fournisseurAttachmentTypes,
			"typeList1": this.typeList1,
			"typeList2": this.typeList2,
			"ouiNon": this.ouiNon,
			"typeFlux": this.typeFlux,
			"typesFertilisant": this.typesFertilisant,
			"typesDigestat": this.typesDigestat,
			"denominationDigestat": this.denominationDigestat,
			"etatDigestat": this.etatDigestat,
			"modesObtention": this.modesObtention,
			"traitementsGisement": this.traitementsGisement,
			"incorporationSource": this.incorporationSource,
			"dureeDeLissage": this.dureeDeLissage,
			"critereVentilationBaseEchange": this.critereVentilationBaseEchange,
			"typeDocument": this.typeDocument,
			"comptaImportHeaders": this.comptaImportHeaders,
			"familleRed2": this.familleRed2,
			"categoriesRed2": this.categoriesRed2,
			"familleFGR": this.familleFGR,
			"red2HelperMessage": this.red2HelperMessage,
			"carburants": this.carburants,
			"methodeCalculGES": this.methodeCalculGES,
			"methodeCalculDurabilite": this.methodeCalculDurabilite,
			"paysEurope": this.paysEurope
		});
	}

	asyncData<T>(data: T) {
		return defer(() => Promise.resolve(data));
	}

	public getParametres() {
		return this.parametres;
	}

	private methodeCalculDurabilite = [
		{id: "moyenne", label: "Moyenne pondérée"},
		{id: "defaut", label: "Valeurs par défaut"}
	]

	private methodeCalculGES = [
		{id: "Réel", label: "Réel"},
		{id: "DDV", label: "DDV"},
		{id: "NUTSII", label: "NUTSII"}
	]

	private carburants = [
		"Supercarburants SP95 et SP98",
		"Supercarburant SP95-E10",
		"Gazole",
		"Electricité",
		"GPL-c",
		"Superéthanol E85",
		"Gaz naturel véhicule",
		"Gazole B30 et le gazole B100",
		"Carburant ED95",
		"Hydrogène",
		"BioGNV",
		"GNR (Gazole non routier)"
	];

	private red2HelperMessage = `
		Les éléments du bilan massique fournis par Biogasview sont déterminés à partir des quantités et des caractéristiques des 
		stocks incorporés dans le digesteur durant la période considérée (un mois calendaire). 
		La démarche suivie commence par un état de stock initial en début de période en termes de tonnage, durabilité, émissions GES et potentiel méthane. 
		Cet état de stock initial est réactualisé chaque jour en tenant compte des réceptions effectuées au cours de la période en question. Sur la base de ces calculs, 
		le système est capable de déterminer les caractéristiques de durabilité, GES et potentiel méthane de chaque incorporation faite pendant la période de calcul. \n\n
		Le tableau ci-dessous vous permet d'initialiser ou de mettre à jour votre état de stock  de début de période. 
		En principe, cette action n'est à faire qu'à la première utilisation du module Red II de Biogasview. 
		En effet, à chaque fois que vous lancez les calculs Red II , le système vous propose d'enregistrer 
		automatiquement l'état de stock en fin de la période considérée afin de s'en servir comme état de stock initial pour la période suivante. 
	`;

	private familleFGR = [  
		"Biodéchets", 
		"Boues de station d'épuration",
		"Décharge (ISDND)",
		"Déchets graisseux pâteux/solides", 
		"Déchets IAA liquides ( < 20% MS)", 
		"Déchets IAA pâteux/solides ( > 20% MS)",
		"Déchets végétaux ensilés",
		"Ensilage de CIVE",
		"Station d'épuration des eaux urbaines",
		"Ensilage de cultures dédiées",
		"Ensilage herbe de prairie permanente",
		"Ensilage herbe de prairie temporaire", 
		"Fumier",
		"Gaz de décharge (ISDND)",
		"Graisses de station d'épuration",
		"Lisier",
		"Résidus de culture (pailles)",
		"Produit agricole, co-produit agricole déjà certifié RED II"
	]

	private familleRed2 = {
		iscc: [
			"Algues",
			"Avoine",
			"Betterave à sucre",
			"beurre de karité",
			"Blé",
			"Boue d'amidon",
			"Boue d'amidon (faible teneur)",
			"Boutures d'arbres fruitiers (issus de l'agriculture)",
			"Bulbe de fleur",
			"Caméline ( lin bâtard ou sésame d'Allemagne )",
			"Canne à sucre",
			"Canne géante  (Miscanthus lutarioriparius  )",
			"Carthame",
			"Colophane d'huile de pin",
			"Colza",
			"Déchets alimentaires",
			"Déchets de bouillie d'amidon",
			"Déchets de pressage (issus de la production d'huiles végétales)",
			"Déchets solides municipaux organiques (MSW)",
			"Déchets / résidus de la transformation d'huile végétale ou animale (spécification de la matière première ou de la culture)",
			"Déchets / résidus de la transformation de l'alcool",
			"Dextrose liquide (LDX)",
			"Distillat d'acide gras de palme (PFAD)",
			"Drêche",
			"Épis de Maïs / Maïs",
			"Farine",
			"Fumier",
			"germe de céréale",
			"Glycérine brute",
			"Glycérine raffinée",
			"graine de coton",
			"Graine de ricin",
			"Graisse animale raffinée / suif (spécification de la catégorie)",
			"Graisse brune / graisse bac à graisse",
			"Graisses animales issues de l'équarrissage (catégorie 1)",
			"Graisses animales issues de l'équarrissage (catégorie 2)",
			"Graisses animales issues de l'équarrissage (catégorie 3)",
			"Graisses animales issues de l'équarrissage (non catégorisé)",
			"Herbe",
			"Huile acide de plume de volaille",
			"Huile brute",
			"Huile de cuisson usagée (UCO)",
			"Huile de cuisson usagée (UCO) entièrement d'origine végétale. Origine",
			"Huile raffinée",
			"Huiles usées issues du traitement des boues d'épuration",
			"Jus concentré de betterave",
			"Jus de canne à sucre",
			"Karitene",
			"L'huile de maïs",
			"Les boues d'épuration",
			"Lies de vin",
			"Maïs",
			"Matière Organique Non-Glycérol (MONG)",
			"Oléine",
			"Orge",
			"Paille",
			"Pâte",
			"Perméat de lactosérum",
			"Résidus de betterave sucrière",
			"Résidus de distillation",
			"Résidus de fibres d'herbe provenant de la production de protéines d'herbe",
			"Résidus de la transformation du maïs",
			"Résidus de traitement forestier",
			"Seigle",
			"Sirop",
			"Soja",
			"Sorgho",
			"Sous-produits animaux (catégorie 1)",
			"Sous-produits animaux (catégorie 2)",
			"Sous-produits animaux (catégorie 3)",
			"Sous-produits animaux (non catégorisé)",
			"Stéarine",
			"Sucre",
			"Terre décolorante usée",
			"Tontes de gazon municipales",
			"Tournesol",
			"Triticale"
		],
		"2bsvs": [
			"Acides gras (palmier, soja, colza / canola, tournesol)",
			"Acides gras estérifiés",
			"Alcool brut de lie de vin",
			"Alcool brut de marcs de raisin",
			"Alcool hydraté",
			"Alcool hydraté brut d'amidons résiduels",
			"Alcool hydraté brut de betterave sucrière",
			"Alcool hydraté brut de marcs de raisin et lies de vin",
			"Alcool hydraté brut de sucre EP2",
			"Alcool hydraté lignocellulosique brut",
			"Algues",
			"Amidon résiduel",
			"Autre biomasse",
			"Autre matériau cellulosique non alimentaire",
			"Autres biomasses",
			"Autres céréales à l'exclusion de l'éthanol de maïs",
			"Autres cultures d'huile",
			"Autres cultures oléagineuses",
			"Autres cultures sucrières",
			"Autres déchets animaux huileux",
			"Autres déchets biogéniques",
			"Autres déchets d'huiles animales",
			"Autres déchets et résidus agricoles",
			"Autres déchets et résidus de l'industrie agroalimentaire ou de l'industrie de l'alimentation animale",
			"Autres eaux usées et dérivés",
			"Autres graisses, huiles et graisses (FOG)",
			"Autres huiles animales usagées",
			"Autres huiles végétales déchet",
			"Autres huiles végétales usagées",
			"Autres intrants",
			"Autres matières premières",
			"Autres matières premières / intermédiaires / produits finis (incluant le RCF et RFONBO)",
			"Autres résidus biogènes",
			"Autres résidus et déchets agricoles",
			"Autres résidus et déchets de transformation alimentaire-alimentaire",
			"Bagasse",
			"Balle de riz",
			"Betterave",
			"Betterave sucrière",
			"Biodéchet",
			"biodéchets",
			"Bio-glycérine / glycérol / glycérine végétale",
			"Biomasse issue des déchets de jardin et de parc",
			"Blé",
			"Boue d'amidon",
			"Boue d'amidon (faible teneur)",
			"Boues d'épuration",
			"Bouillie d'amidon",
			"Bouillie d'amidon (basse qualité)",
			"Brai d'acide gras distillé d'huile végétale",
			"Brassica Carinata / Carinata",
			"Caméline",
			"Canne à sucre",
			"Canne à sucre / Paille de canne à sucre",
			"Canola",
			"Carinata (moutarde d'Abyssinie)",
			"Carthame des teinturiers",
			"Chanvre (cannabis)",
			"Chardon",
			"Chardon-Marie",
			"Colza",
			"Coques de tournesol",
			"Coques/écorces et dérivés, enveloppes de soja",
			"Coquilles / coques et dérivés, coques de soja",
			"Coquilles de noix",
			"Couverts végétaux, cultures dérobées (CIVES)",
			"Coton",
			"Croton",
			"Croûte de laitier de fromagerie",
			"Culture Intermédiaire à Valorisation Energétique (CIVE)",
			"Cultures énergétiques",
			"Cultures intercalaires",
			"Déchets / résidus de la transformation de l'alcool",
			"Déchets alimentaires",
			"Déchets alimentaires huileux",
			"Déchets contaminés du processus de fabrication du sucre",
			"Déchets d'aliments pour animaux",
			"Déchets de la pêche",
			"Déchets de l'industrie agroalimentaire",
			"Déchets de l'industrie alimentaire",
			"Déchets de l'industrie laitière",
			"Déchets et résidus industriels",
			"Déchets municipaux mélangés (à l'exclusion des déchets ménagers triés)",
			"Déchets municipaux solides organiques (MSW)",
			"Déchets solides municipaux organiques (MSW)",
			"Déchets/résidus de la transformation d'huiles végétales ou animales",
			"Déchets d'alimentation animale",
			"Déchet de Séparateur de Graisse",
			"Distillat d'acide gras de palme (PFAD)",
			"Eaux usées provenant du transport maritime",
			"Effluent de moulin à huile de palme (POME)",
			"Effluent de moulin à huile de palme et fruits de palme vides",
			"Effluent d'huile  de palme et grappes de fruits de palmier vides",
			"Ensilage",
			"Ensilage d'herbe",
			"Ensilage de CIVE",
			"Ensilage de culture dédiée",
			"Ensilage de maïs",
			"Épis de maïs débarrassés de leurs grains",
			"ETBE (ethyl-tertio-butyl-ether produit à base d'éthanol)",
			"Éthanol de betterave sucrière",
			"Ethanol de canne à sucre",
			"Ethanol de maïs",
			"Eaux usées de l'industrie alimentaire",
			"Ethanol fait à partir d'énergies renouvelables",
			"Farine de palmiste",
			"Farine de tournesol",
			"Favino",
			"Fourrage non utilisé provenant de la jachère",
			"Fraction biomasse des déchets municipaux mélangés",
			"Fraction de biomasse des déchets industriels",
			"Fraction de biomasse des déchets municipaux mélangés",
			"Fraction de la biomasse des déchets et résidus de l'activité sylvicole",
			"Fumier",
			"Fumier animal et boues d'épuration",
			"Fumier avec déchets organiques",
			"Fumier avec déchets organiques et cultures énergétiques",
			"Fumier avec des déchets organiques et des cultures énergétiques",
			"Fumier humide",
			"Gaz de décharge",
			"Gaz de station d'épuration",
			"Glycérine brute",
			"Glycérine raffinée",
			"Gommes acides",
			"Grain de seigle",
			"Graine de canola",
			"Graine de coton",
			"Graine de lin",
			"Graine de moutarde d'Abyssinie",
			"Graines de Carinata",
			"Graines de coton",
			"Graines de tournesol",
			"Graines d'orge",
			"graines oléagineuses",
			"Graisse animale / huiles / suif (catégorie 1)",
			"Graisse animale / huiles / suif (catégorie 2)",
			"Graisse animale / huiles / suif (catégorie 3)",
			"Graisse brune",
			"Graisse brune / graisse de bac à graisse",
			"Graisses",
			"Graisses animales classées en catégories 1 et 2 conformément au Règlement (CE) n° 1069/2009",
			"Graisses animales d'usine de biodiesel",
			"Graisses des stations d'épuration",
			"Herbe",
			"Huile alimentaire usagée : huile extraite des déchets alimentaires de l'industrie",
			"Huile brute",
			"Huile de boue de palme (PSO)",
			"Huile de cameline",
			"Huile de canola brut",
			"Huile de canola semi-raffinée / raffinée",
			"Huile de carinata brute",
			"Huile de carinata semi-raffinée / raffinée",
			"huile de colza brute",
			"Huile de colza hydrotraitée",
			"Huile de colza semi-raffinée / raffinée",
			"Huile de croton brut",
			"Huile de croton semi-raffinée / raffinée",
			"Huile de cuisson usagée",
			"Huile de cuisson usagée entièrement d'origine végétale",
			"Huile de cuisson usagée entièrement ou partiellement d'origine animale",
			"Huile de déchets d'huile de cuisson hydrotraitée",
			"Huile de 'Empty Palm Fruit Bunch (EFB)'",
			"Huile de fibre de palme pressée",
			"Huile de graines de caméline",
			"Huile de graines de coton brut",
			"Huile de graines de coton semi-raffinée / raffinée",
			"Huile de graisse animale hydrotraitée",
			"Huile de grappes de fruits de palmier vides",
			"Huile de maïs",
			"Huile de maïs technique",
			"Huile de palme (bassin de traitement des effluents ouvert) hydrotraitée",
			"huile de palme brute",
			"Huile de palme hydrotraitée",
			"Huile de palme semi-raffinée / raffinée",
			"Huile de palmiste",
			"Huile de ricin brut",
			"Huile de ricin semi-raffinée / raffinée",
			"huile de soja brut",
			"Huile de soja hydrotraitée",
			"Huile de soja semi-raffinée / raffinée",
			"Huile de tall (tallöl)",
			"Huile de terres décolorantes usées",
			"Huile de tournesol brut",
			"Huile de tournesol hydrotraitée",
			"Huile de tournesol semi-raffinée / raffinée",
			"Huile raffinée",
			"Huile technique de maïs",
			"Huile végétale",
			"Huile végétale brute",
			"Huile végétale brute de colza",
			"Huile végétale brute de soja",
			"Huile végétale brute de tournesol",
			"Huile végétale brute d'huile de palme",
			"Huile végétale hydrotraitée",
			"Huile végétale pure",
			"Huiles acides",
			"Huiles acides des pâtes de neutralisation",
			"Huiles lubrifiantes dérivées d'acides gras",
			"Ivraie",
			"Jatropha / Jatropha curcas",
			"Jatropha / Pourghère",
			"Jojoba",
			"Jus de canne à sucre",
			"Karité",
			"L'huile de maïs",
			"Lisier",
			"Luzerne",
			"Maïs",
			"Marcs de raisin et lies de vin",
			"Mélasse",
			"Méthanol fait à partir d'énergies renouvelables",
			"Noix de karité",
			"Oléine",
			"Oléine de palme",
			"Oléine de palmiste",
			"Olives",
			"Orge",
			"Paille",
			"Paille de blé",
			"Paille de canne à sucre",
			"Palm Fatty Acid Distillate (PFAD)",
			"Palm Oil Mill Effluent (POME) and Empty Palm Fruit Bunches (EFB)",
			"Palmiste / Coquille de palmiste",
			"Pâte de neutralisation mixte de déchets et de résidus",
			"Phacélie",
			"Plant entier de tournesol",
			"Plante entière de maïs",
			"Plante entière de tournesol",
			"Pulpes de betterave",
			"Pongamia",
			"Ray-grass",
			"Résidus de bacs à graisse",
			"Résidus de pressage (issus de la production d'huiles végétales)",
			"Résidus de récolte agricole",
			"Résidus/mélasse de betterave sucrière",
			"Résidus-mélasses de betterave sucrière (coproduit)",
			"Résidus-mélasses de betterave sucrière (déchet)",
			"Ricin",
			"Savon de la production de sulfate",
			"Soja",
			"Son (coques)",
			"Sorgho",
			"Sources renouvelables de méthyl-tertio-butyl-éther (MTBE)",
			"Sous-produits animaux (catégorie 1)",
			"Sous-produits animaux (catégorie 2)",
			"Sous-produits animaux (catégorie 3)",
			"Substrat SBG / SBM pour le biogaz / biométhane",
			"Sucre",
			"Sulla",
			"Tabac",
			"Terres décolorantes usées",
			"Trèfle",
			"Triglycérides",
			"Triticale",
			"Tung / Abrasin / Bois de Chine",
			"Vicia"
		]
	}

	private critereVentilationBaseEchange = [
		{
			"nom": "Matière sèche",
			"id": 10,
			"unite": "%"
		},
		{
			"nom": "Matière organique",
			"id": 20,
			"unite": "%"
		},
		{
			"nom": "Azote",
			"id": 130,
			"unite": "kg/t MB"
		},
		{
			"nom": "Phosphore",
			"id": 140,
			"unite": "kg/t MB"
		},
		{
			"nom": "Potassium",
			"id": 150,
			"unite": "kg/t MB"
		}
	]
	private incorporationSource = [
		'Calcul automatique depuis les chargements', 
		'Saisie manuelle',
		'Calcul automatique depuis les données de supervision',
		'Calcul automatique depuis la supervision et les chargements'
	]

	private typesComptePCG = [
		{ id: 'Compte de produits', label: 'Compte de produits' },
		{ id: 'Compte de TVA', label: 'Compte de TVA' }
	];

	private affichageTableauParDefaut = [ 'Tableau', 'Graphique'];

	private affichageGraphiqueParDefaut = ['Courbes', 'Histogramme empilé', 'Histogramme côte à côte'];

	private typeContrat = [
		{ id: 'Avec engagement', label: 'Avec engagement'},
		{ id: 'Sans engagement', label: 'Sans engagement'}
	];

	private typeVariables = ['Mesure', 'Compteur', 'Contrôle', 'Commentaire', 'Digestat'];

	private exportDataFileType = ['csv', 'pdf', 'xls'];

	private expeditionDestinationType = [
		{ id: 'Stock de digestat', label: 'Stock de digestat' },
		{ id: 'Parcelle', label: 'Parcelles' },
		{ id: 'Autre', label: 'Autre' }
	];

	private analyseFileTypes = [{ id: 'FICHIER SADEF', label: 'FICHIER SADEF' }];

	private dureeDeLissage = new Array(21).fill(0).map((v,i) => i+1);

	private typeFlux = ['Réception', 'Expédition'];

	private ceLabel = {
		"Coût achat/redevance": "Coût achat/redevance",
		"Coût transport": "Coût transport",
		"Coût traitement": "Coût traitement",
		"Coût total": "Coût total"
	};

	private ceUnit = {
		"€/t MB": "€/t MB"
	};

	private typeAnalyse = [
		{ id: "Labo", label: "Labo"},
		{ id: "Interne", label: "Interne"}
	]

	private updateCpcExpeditionList = [
		{ id: "MS", label: "MS" },
		{ id: "MO", label: "MO" },
		{ id: "P2O5", label: "Phosphore" },
		{ id: "K2O", label: "Potassium" },
		{ id: "Ntot", label: "Azote total" },
		{ id: "nLot", label: "N° de lot" },
		{ id: "commentaire", label: "Commentaire" },
	]

	private updateCpcGisementList = [
		{ id: "MS", label: "MS" },
		{ id: "MO", label: "MO" },
		{ id: "PM", label: "PM" },
		{ id: "Ntot", label: "Azote total" },
		{ id: "P2O5", label: "Phosphore" },
		{ id: "K2O", label: "Potassium" },
	]


	private updateCpcList = [
		{ id: "MS", label: "MS" },
		{ id: "MO", label: "MO" },
		{ id: "PM", label: "PM" },
		{ id: "Ntot", label: "Azote total" },
		{ id: "nLot", label: "N° de lot" },
		{ id: "commentaire", label: "Commentaire" },
	]

	private updateFacturationList = [
		{ id: "client", label: "Client" },
		{ id: "fournisseur", label: "Fournisseur" },
	]

	private modules = [
		{ id: "reception", label: "Réceptions" },
		{ id: "gisement", label: "Gisements" },
		{ id: "tiers", label: "Tiers" },
		{ id: "stockIntrant", label: "Stock d'intrants" },
	]

	private typeList1 = [
		{ id: "Transporteur", label: "Transporteur" },
		{ id: "Négociant", label: "Négociant" },
	]

	private ouiNon = [
		{ id: "Oui", label: "Oui" },
		{ id: "Non", label: "Non" },
	]

	private typeList2 = [
		{ id: "Destinataire", label: "Destinataire" },
		{ id: "Transporteur", label: "Transporteur" },
		{ id: "Négociant", label: "Négociant" },
	]

	private blocs = [
		{
			id: "appro",
			label: "Appro",
			modules: [
				{ id: "reception", label: "Réceptions" },
				{ id: "commandeGisement", label: "Commandes" },
				{ id: "planningReception", label: "Planning" },
				{ id: "tiers", label: "Tiers" },
				{ id: "gisement", label: "Gisements" },
				{ id: "planAppro", label: "Plan d\'appro" }
			]
		},
		{
			id: "intrant",
			label: "Intrants",
			modules: [
				{ id: "gisement", label: "Gisements" },
				{ id: "tiers", label: "Tiers" },
				{ id: "analyseIntrant", label: "Analyses d\'intrants" },
				{ id: "stockIntrant", label: "Stock d\'intrants" },
				{ id: "contratClient", label: "Contrats client" },
				{ id: "contratFournisseur", label: "Contrats fournisseur" },
				{ id: "badges", label: "Badges" }
			]
		},
		{
			id: "ration",
			label: "Rations",
			modules: [
				{ id: "rationHebdo", label: "Incorporations" },
				{ id: "consigneHebdo", label: "Consignes" },
				{ id: "stockIntrant", label: "Stock d\'intrants" },
				{ id: "ouvragesIncorporation", label: "Ouvrages d\'incorporation" },
				{ id: "ouvragesDigestion", label: "Ouvrages de digestion" }
			]
		},
		{
			id: "digestat",
			label: "Digestats",
			modules: [
				{ id: "stockDigestat", label: "Stock de digestat" },
				{ id: "commandeDigestat", label: "Commandes" },
				{ id: "analyseDigestat", label: "Analyses de digestat" },
				{ id: "expedition", label: "Expéditions" },
				{ id: "tiers", label: "Tiers" },
				{ id: "badges", label: "Badges" }
			]
		},
		{
			id: "epandage",
			label: "Epandages",
			modules: [
				{ id: "expedition", label: "Expéditions" },
				{
					id: "planExport",
					label: "Plan d'export",
					children: [
						{ id: "digestatliquide", label: "Digestat liquide" },
						{ id: "digestatsolide", label: "Digestat solide" },
						{ id: "digestatbrut", label: "Digestat brut" },
						{ id: "digestatseche", label: "Digestat séché" },
						{ id: "autreproduit", label: "Autre produit" }
					]
				},
				{ id: "parcelle", label: "Parcelles" },
				{ id: "tiers", label: "Tiers" },
				{ id: "baseEchange", label: "Base d'échange" }
			]
		},
		{
			id: "facturation",
			label: "Facturation",
			modules: [
				{ id: "factureClient", label: "Factures client" },
				{ id: "factureFournisseur", label: "Factures fournisseur" },
				{ id: "contratClient", label: "Contrats client" },
				{ id: "contratFournisseur", label: "Contrats fournisseur" },
				{ id: "contratEchange", label: "Contrats d\'échange" },
				{ id: "tiers", label: "Tiers" }
			]
		},
		{
			id: "suivi",
			label: "Suivi d'exploitation",
			modules: [
				{ id: "saisie", label: "Saisies manuelles" }
			]
		},
		{
			id: "rondesExploit",
			label: "Rondes",
			modules: [
				{ id: "saisies", label: "Saisies manuelles" },
				{ id: "rondes", label: "Rondes" },
				{ id: "variables", label: "Variables" },
				{ id: "tableaux", label: "Tableaux" }
			]
		},
		{
			id: "journal",
			label: "Journal",
			modules: [
				{ id: "calendrier", label: "Calendrier" },
				{ id: "evenements", label: "Evénements" },
				{ id: "intervenants", label: "Intervenants" },
				{ id: "taches", label: "Tâches" },
				{ id: "ticketMaintenance", label: "Tickets de maintenance" },
				{ id: "ficheIntervention", label: "Fiches d'intervention" },
				{ id: "indispos", label: "Indispos" },
			]
		},

		{
			id: "materiel",
			label: "Materiel",
			modules: [
				{ id: "equipements", label: "Equipements" },
				{ id: "stockPieces", label: "Stock des pièces" },
				{ id: "pieces", label: "Pièces" },
				{ id: "consommables", label: "Consommables" },
				{ id: "tickets", label: "Tickets" },
				{ id: "contratMaintenance", label: "Contrats de maintenance" },
			]
		},
		{
			id: "ration2",
			label: "Rations 2",
			modules: [
				{
					id: "incorporations",
					label: "Ration",
					children: [
						{ "id": "rationincorporation", "label": "Incorporations"},
				        { "id": "rationbilan", "label": "Bilan" }
					]
				},
				{ id: "consignes", label: "Consignes" },
				{ id: "ouvragesIncorporation", label: "Ouvrages d\'incorporation" },
				{ id: "ouvragesDigestion", label: "Ouvrages de digestion" },
				{
					id: "stockIntrant",
					label: "Stock d\'intrants",
					children: [
						{ id: "rationliststock", label: "Liste des stocks" },
						{ id: "stocketat", label: "Etats des stocks" }
					]
				},
			]
		},
		{
			id: "red2",
			label: "RED II",
			modules: [
				{ id: "bilanRed2", label: "Bilan massique" },
				{
					id: "stockIntrant",
					label: "Stock d\'intrants"
				},
				{
					id: "releveRed2",
					label: "Relevés"
				},
				{
					id: "parametreRed2",
					label: "Paramètres"
				},
			]
		}
	];

	private routes = [
		{ id: "appro", path: "appro" },
		{ id: "intrant", path: "intrants" },
		{ id: "ration", path: "rations" },
		{ id: "digestat", path: "digestats" },
		{ id: "epandage", path: "epandages" },
		{ id: "facturation", path: "facturation" },
		{ id: "reception", path: "reception" },
		{ id: "commandeGisement", path: "commandes-gisement" },
		{ id: "commandeDigestat", path: "commandes-digestat" },
		{ id: "planningReception", path: "planning" },
		{ id: "tiers", path: "tiers" },
		{ id: "gisement", path: "gisements" },
		{ id: "planAppro", path: "plan-appro" },
		{ id: "analyseIntrant", path: "analyses" },
		{ id: "stockIntrant", path: "stock-intrant" },
		{ id: "rationHebdo", path: "incorporations" },
		{ id: "consigneHebdo", path: "consignes" },
		{ id: "stockDigestat", path: "stock-digestat" },
		{ id: "ouvragesIncorporation", path: "ouvrages-incorporation" },
		{ id: "ouvragesDigestion", path: "ouvrages-digestion" },
		{ id: "analyseDigestat", path: "analyses" },
		{ id: "expedition", path: "expeditions" },
		{ id: "parcelle", path: "parcelles" },
		{ id: "planExport", path: "planning" },
		{ id: "factureClient", path: "factures-client" },
		{ id: "factureFournisseur", path: "factures-fournisseur" },
		{ id: "contratClient", path: "contrat-client" },
		{ id: "contratFournisseur", path: "contrat-fournisseur" },
		{ id: "contratEchange", path: "contrat-echange" },
		{ id: 'suivi', path: 'suivi' },
		{ id: "saisie", path: "saisies" },
		{ id: "digestatliquide", path: "planExport/digestatliquide" },
		{ id: "digestatsolide", path: "planExport/digestatsolide" },
		{ id: "digestatbrut", path: "planExport/digestatbrut" },
		{ id: "digestatseche", path: "planExport/digestatseche" },
		{ id: "autreproduit", path: "planExport/autreproduit" },
		{ id: "rondesExploit", path: "rondesExploit" },
		{ id: "rondes", path: "rondes" },
		{ id: "variables", path: "variables" },
		{ id: "tableaux", path: "tableaux" },
		{ id: "saisies", path: "saisieRondes" },
		{ id: "journal", path: "journal" },
		{ id: "materiel", path: "materiel" },
		{ id: "calendrier", path: "calendrier" },
		{ id: "evenements", path: "evenements" },
		{ id: "intervenants", path: "intervenants" },
		{ id: "taches", path: "taches" },
		{ id: "pieces", path: "pieces" },
		{ id: "equipements", path: "equipements" },
		{ id: "contratMaintenance", path: "contrats-de-maintenance" },
		{ id: "ticketMaintenance", path: "tickets-de-maintenance" },
		{ id: "ficheIntervention", path: "fiches-intervention" },
		{ id: "indispos", path: "indispos" },
		{ id: "stockPieces", path: "stock-des-pieces" },
		{ id: "materiel", path: "materiel" },
		{ id: "consommables", path: "consommables" },
		{ id: "badges", path: "badges" },
		{ id: "ration2", path: "ration2"},
		{ id: "incorporations", path: "incorporations"},
		{ id: "rationincorporation", path: "incorporations/rationincorporation"},
		{ id: "rationbilan", path: "incorporations/rationbilan"},
		{ id: "consignes", path: "consignes"},
		{ id: "rationliststock", path: "stock-intrant/list"},
		{ id: "stocketat", path: "stock-intrant/stock-etat"},
		{ id: "baseEchange", path: "baseEchange"},
		{ id: "red2", path: "red2" },
		{ id: "bilanRed2", path: "bilan-red2" },
		{ id: "releveRed2", path: "releve-red2" },
		{ id: "parametreRed2", path: "parametre-red2" },
	];

	private blocLabels = [
		{ id: "appro", label: "Approvisionnements" },
		{ id: "intrant", label: "Intrants" },
		{ id: "ration", label: "Rations" },
		{ id: "digestat", label: "Digestats" },
		{ id: "epandage", label: "Épandages" },
		{ id: "facturation", label: "Facturation" },
		{ id: 'suivi', label: 'Suivi d\'exploitation' },
		{ id: 'rondesExploit', label: 'Rondes' },
		{ id: 'journal', label: 'Journal' },
		{ id: 'materiel', label: 'Materiel' },
		{ id: "ration2", label: "Ration 2"},
		{ id: "red2", label: "RED II"}
	]

	private superBlocs = [
		{
			id: 'tracabilite',
			blocs: ['appro', 'intrant', 'ration', 'ration2', 'red2', 'digestat', 'epandage', 'facturation']
		},
		{
			id: 'exploitation',
			blocs: ['suivi', 'rondesExploit', 'journal', 'materiel']
		}
	]

	private modulesPerimetreRestreint = [
		['Plan d\'appro', 'plan-appro'],
		['Plan d\'export', 'plan-export'],
		['Rondes', 'rondesExploit'],
		['Ration 2', "ration2"],
		['Red 2', "red2"]
	]

	private typesAnalyse = [
		{id: 'Analyse physique', label: 'Analyse physique'},
		{id: 'Analyse BMP', label: 'Analyse BMP'},
		{id: 'Analyse agronomique', label: 'Analyse agronomique'},
		{id: 'Analyse nutritionnelle', label: 'Analyse nutritionnelle'},
		{id: 'Analyse oligo-éléments et ETM', label: 'Analyse oligo-éléments et ETM'},
		{id: 'Analyse sanitaire', label: 'Analyse sanitaire'},
		{id: 'Analyse CTO', label: 'Analyse CTO'},
	]

	private headersExportCompta = [
		"Société", "Compte", "Entrées/Sorties", "Code sections", "Codes Analytiques", "Service", "Budget", "Poste", "Échéance",
		"Contre partie", "Taux TVA", "Montant HT", "TVA", "Montant TTC", "Commandé", "Date commande",
		"Date facturation", "Date d'échéance", "Date paiement", "Date de débit", "Réalisé & rapproché", "Semaine", "Mois",
		"Année", "Mode paiement", "N° du mvt", "N° de facture", "in/ex", "comptes bancaires", "Tirage bancaire - date",
		"Annéebudgétaire", "Factures Transmis à Korus", "Commentaire"
	]

	private statutsContrat = [
		{id: 'À finaliser', label: 'À finaliser'},
		{id: 'À renouveler', label: 'À renouveler'},
		{id: 'Terminé', label: 'Terminé'},
		{id: 'En cours', label: 'En cours'},
		{id: 'Ponctuel', label: 'Ponctuel'},
	]

	private statutsPiece = [
		"En service",
		"En stock",
		"Retiré",
		""
	]

	private typesDigestat = [
		"Amendement organique",
		"Engrais organique"
	]

	private denominationDigestat = [
		"Digestat de méthanisation d'intrants exclusivement agricoles",
		"Digestat de méthanisation d'intrants agricoles et agro-alimentaires",
	]

	private etatDigestat = [
		"Liquide",
		"Solide"
	]

	private modesObtention = [
		"Digestat brut",
		"Fraction liquide après séparation de phase",
		"Fraction solide après séparation de phase",
	]

	private typesFertilisant = [
		"type I",
		"type II"
	]

	private interventionProblemeResolu = [
		{ label: 'Oui', value: true }, { label: 'Non', value: false }
	];

	private clauseQualitativeArticle = [
		{ id: 'Non', label: 'Non'},
		{ id: 'MS', label: 'MS'}
	];

	private categorieArticleContratClient = [
		{id: 'Gisement', label: 'Gisement'},
		{id: 'Digestat', label: 'Digestat'},
		{id: 'Producteur', label: 'Producteur'},
		{id: 'Autres ventes', label: 'Autres ventes'},
	];

	private categorieArticleContratFournisseur = [
		{id: 'Gisement', label: 'Gisement'},
		{id: 'Digestat', label: 'Digestat'},
		{id: 'Producteur', label: 'Producteur'},
		{id: 'Autres achats', label: 'Autres achats'},
	];

	private uniteFonctionnelle = ["Appro/Incorporation", "Digestion", "Valorisation", "Digestat", "Autre"];

	private receptionAttachmentTypes = [
		{id: 'BSD', label: 'BSD'},
		{id: 'DAC', label: 'DAC'},
		{id: 'TICKET', label: 'TICKET'},
		{id: 'Pièce jointe standard', label: 'Pièce jointe standard'},
		{id: 'Lettre de voiture', label: 'Lettre de voiture'}
		]

	private expeditionAttachmentTypes = ["BSD", "DAC", "Ticket", "Pièce jointe standard"];

	private contratClientAttachmentTypes = ["Pièce jointe standard"];

	private contratFournisseurAttachmentTypes = ["Pièce jointe standard"];

	private gisementAttachmentTypes = [
		{id: "FIPA-CAP", label: "FIPA, CAP"},
		{id: "Justificatif-de-durabilite", label: "Justificatif de durabilité"},
		{id: "Pièce jointe standard", label: "Autre"},
	];

	private clientAttachmentTypes = [
		{id: 'Pièce jointe standard', label: 'Pièce jointe standard'},
	];

	private tiersAttachmentTypes = [
		{id: "RIB", label: "RIB"},
		{id: "KBIS", label: "KBIS"},
		{id: "Certificat-d-assurance-rc", label: "Certificat d'assurance RC"},
		{id: "Document-sanitaire", label: "Document sanitaire (bilan, attestation)"},
		{id: "Justificatif-de-durabilite", label: "Justificatif de durabilité"},
		{id: "Certification-Red2", label: "Certification Red 2"},
		{id: "Recepisse-declaration-transport", label: "Récépissé déclaration transport de déchet"},
		{id: "Autorisation-transport-SPAN", label: "Autorisation transport SPAN"},
		{id: "Licence-transporteur", label: "Licence transporteur"},
		{id: "Protocole-securite", label: "Protocole de sécurité"},
		{id: "Pièce jointe standard", label: "Autre"},
	];

	private fournisseurAttachmentTypes = [
		{id: 'Pièce jointe standard', label: 'Pièce jointe standard'},
	];

	private analyseIntrantAttachmentTypes = [
		{id: 'Pièce jointe standard', label: 'Pièce jointe standard'},
	];

	private ticketAttachmentTypes = ["Pièce jointe standard"];

	private interventionAttachmentTypes = ["Pièce jointe standard"];

	private contratMaintenanceAttachmentTypes = ["Pièce jointe standard"];

	private imputationAnalytique = ["Appro/Incorporation", "Biologie", "Technique", "Réseau", "Autre"];

	private typeTicket = ["Maintenance curative", "Maintenance Préventive", "Info"];

	private typeIndispo = ["Prévu", "Imprévu"];

	private moyenNotification = ["Téléphone", "Email", "Autre"];

	private criticiteTicket = ["Critique", "Majeur", "Mineur", "Info"];

	private statutTicket = ["En cours", "Terminé", "Info"];

	private comptaImportHeaders = [
		"Société", "Compte", "Entrées/Sorties", "Code sections", "Codes Analytiques", "Service", "Budget", "Poste",
		"Échéance", "Contre partie", "Taux TVA", "Montant HT", "TVA", "Montant TTC", "Commandé", "Date commande",
		"Date facturation", "Date d'échéance", "Date paiement", "Date de débit", "Réalisé & rapproché", "Semaine", "Mois",
		"Année", "Mode paiement", "N° du mvt", "N° de facture", "in/ex", "comptes bancaires", "Tirage bancaire - date",
		"Annéebudgétaire", "Factures Transmis à Korus", "Commentaire"
	];

	private typeDocument = [
		{id: 'Facture', label: 'Facture'},
		{id: 'Avoir', label: 'Avoir'}
	]

	private traitementsGisement = [
		{id: 'Aucun', label: 'Aucun'},
		{id: 'Déconditionnement', label: 'Déconditionnement'},
		{id: 'Compostage', label: 'Compostage'},
		{id: 'Hygiénisation', label: 'Hygiénisation'},
		{id: 'Désemballage et hygiénisation', label: 'Désemballage et hygiénisation'},
	];

	private statutFactureClient = [
		{id: 'Brouillon', label: 'Brouillon'},
		{id: 'En attente - Quantité incorrecte', label: 'En attente - Quantité incorrecte'},
		{id: 'En attente - PU incorrect', label: 'En attente - PU incorrect'},
		{id: 'Validée', label: 'Validée'},
		{id: 'Envoyée', label: 'Envoyée'},
		{id: 'Payée', label: 'Payée'},
		{id: 'En retard', label: 'En retard'},
	]

	private statutFactureClientExport = [
		"Validée",
		"Envoyée",
		"Paiement reçu - montant incorrect",
		"Paiement reçu - montant correct"
	];

	private statutFactureFournisseur = [
		{id: 'Brouillon', label: 'Brouillon'},
		{id: 'En attente - Quantité incorrecte', label: 'En attente - Quantité incorrecte'},
		{id: 'En attente - PU incorrect', label: 'En attente - PU incorrect'},
		{id: 'Validée', label: 'Validée'},
		{id: 'Facture reçue - montant incorrect', label: 'Facture reçue - montant incorrect'},
		{id: 'Facture reçue - montant correct', label: 'Facture reçue - montant correct'},
		{id: 'Facture reçue - montant correct', label: 'Facture reçue - montant correct'},
		{id: 'Payée', label: 'Payée'},
		{id: 'En retard', label: 'En retard'},
	]

	private statutFactureFournisseurExport = [
		"Validée",
		"Facture reçue - montant incorrect",
		"Facture reçue - montant correct",
		"Payée"
	];

	private indexationArticle = ["Mensuelle", "Annuelle", "Non"];

	private quantitePrevueArticle = [
		{id: 'Mensuelle', label: 'Mensuelle'},
		{id: 'Annuelle', label: 'Annuelle'},
		{id: 'Non', label: 'Non'},
	];

	private numComptePCGArticle = ["601000"];

	private typeSaisie = ["Compteur", "Mesure"];

	private tva = {
        "France": 20,
		"Belgique": 21
	};

	private tauxTva = {
		"France" : [20, 5.5, 10, 0],
		"Belgique" : [21, 6, 0]
	};

	private devises = ["€"];

	private periodesFacturation = [
		{ id: 'Mensuelle', label: 'Mensuelle'},
		{ id: 'Annuelle', label: 'Annuelle'},
		{ id: 'Ponctuelle', label: 'Ponctuelle'},
		];

	private modesPaiement = [
		{ id: 'Chèque', label: 'Chèque'},
		{ id: 'Virement', label: 'Virement'},
		{ id: 'LCR', label: 'LCR'},
		{ id: 'Autre', label: 'Autre'},
      ];

	private articlesContratClient = [
		{id: 'Vente de matière (Tonnes)', label: 'Vente de matière (Tonnes)'},
		{id: 'Vente de matières interco (Tonnes)', label: 'Vente de matières interco (Tonnes)'},
		{id: 'Vente de transport de matière (Tonnes)', label: 'Vente de transport de matière (Tonnes)'},
		{id: 'Vente de transport de matières interco (Tonnes)', label: 'Vente de transport de matières interco (Tonnes)'},
		{id: 'Vente de transport de matière (Rotations)', label: 'Vente de transport de matière (Rotations)'},
		{id: 'Vente de transport de matières interco (Rotations)', label: 'Vente de transport de matières interco (Rotations)'},
		{id: 'Vente de transport de matière (Colis)', label: 'Vente de transport de matière (Colis)'},
		{id: 'Vente de transport de matière (Heure)', label: 'Vente de transport de matière (Heure)'},
		{id: 'Vente de digestat (Tonnes)', label: 'Vente de digestat (Tonnes)'},
		{id: 'Vente de transport de digestat (Tonnes)', label: 'Vente de transport de digestat (Tonnes)'},
		{id: 'Vente de transport de digestat (Rotations)', label: 'Vente de transport de digestat (Rotations)'},
		{id: 'Vente de transport de digestat (Colis)', label: 'Vente de transport de digestat (Colis)'},
		{id: 'Vente de transport de digestat (Heure)', label: 'Vente de transport de digestat (Heure)'},
		{id: 'Vente de prestation d\'épandage de digestat (Colis)', label: 'Vente de prestation d\'épandage de digestat (Colis)'},
		{id: 'Vente de prestation d\'épandage de digestat (Heure)', label: 'Vente de prestation d\'épandage de digestat (Heure)'},
		{id: 'Vente de prestation d\'épandage de digestat (Rotations)', label: 'Vente de prestation d\'épandage de digestat (Rotations)'},
		{id: 'Vente de prestation d\'épandage de digestat (Tonnes)', label: 'Vente de prestation d\'épandage de digestat (Tonnes)'},
		{id: 'Vente de prestation de traitement de matière (Tonnes)', label: 'Vente de prestation de traitement de matière (Tonnes)'},
		{id: 'Vente de prestation de traitement de matière (Colis)', label: 'Vente de prestation de traitement de matière (Colis)'},
		{id: 'Vente de prestation de traitement de matières interco (Tonnes)', label: 'Vente de prestation de traitement de matières interco (Tonnes)'},
		{id: 'Autres ventes', label: 'Autres ventes'},
		{id: 'Autres ventes interco', label: 'Autres ventes interco'},
		{id: 'Autres ventes prime electricite', label: 'Prime électricité'},
	];

	private articlesContratFournisseur = [
		{id: 'Achat de matière (Tonnes)', label: 'Achat de matière (Tonnes)'},
		{id: 'Achat de matières interco (Tonnes)', label: 'Achat de matières interco (Tonnes)'},
		{id: 'Achat de transport de matière (Tonnes)', label: 'Achat de transport de matière (Tonnes)'},
		{id: 'Achat de transport de matières interco (Tonnes)', label: 'Achat de transport de matières interco (Tonnes)'},
		{id: 'Achat de transport de matière (Rotations)', label: 'Achat de transport de matière (Rotations)'},
		{id: 'Achat de transport de matières interco (Rotations)', label: 'Achat de transport de matières interco (Rotations)'},
		{id: 'Achat de transport de matière (Colis)', label: 'Achat de transport de matière (Colis)'},
		{id: 'Achat de transport de matière (Heure)', label: 'Achat de transport de matière (Heure)'},
		{id: 'Achat de transport de digestat (Tonnes)', label: 'Achat de transport de digestat (Tonnes)'},
		{id: 'Achat de transport de digestat (Rotations)', label: 'Achat de transport de digestat (Rotations)'},
		{id: 'Achat de transport de digestat (Heure)', label: 'Achat de transport de digestat (Heure)'},
		{id: 'Achat de transport de digestat (Colis)', label: 'Achat de transport de digestat (Colis)'},
		{id: 'Achat de prestation d\'épandage de digestat (Colis)', label: 'Achat de prestation d\'épandage de digestat (Colis)'},
		{id: 'Achat de prestation d\'épandage de digestat (Heure)', label: 'Achat de prestation d\'épandage de digestat (Heure)'},
		{id: 'Achat de prestation d\'épandage de digestat (Rotations)', label: 'Achat de prestation d\'épandage de digestat (Rotations)'},
		{id: 'Achat de prestation d\'épandage de digestat (Tonnes)', label: 'Achat de prestation d\'épandage de digestat (Tonnes)'},
		{id: 'Achat de prestation de traitement de matières interco (Tonnes)', label: 'Achat de prestation de traitement de matières interco (Tonnes)'},
		{id: 'Autres achats', label: 'Autres achats'},
		{id: 'Autres achats interco', label: 'Autres achats interco'},
		{id: 'Autres achats prime electricite', label: 'Prime électricité'},
	];

	private conditionsPaiement = [
		{ id: 'Comptant (A réception de facture)', label: 'Comptant (A réception de facture)'},
		{ id: 'Fin du mois - date de facture', label: 'Fin du mois - date de facture'},
		{ id: '30 jours - date de facture', label: '30 jours - date de facture'},
		{ id: '30 jours fin de mois - date de facture', label: '30 jours fin de mois - date de facture'},
		{ id: '45 jours - date de facture', label: '45 jours - date de facture'},
		{ id: '45 jours fin de mois - date de facture', label: '45 jours fin de mois - date de facture'},
		{ id: '60 jours - date de facture', label: '60 jours - date de facture'},
		{ id: '60 jours fin de mois - date de facture', label: '60 jours fin de mois - date de facture'},
		{ id: '8 jours - date de facture', label: '8 jours - date de facture'},
		{ id: '15 jours - date de facture', label: '15 jours - date de facture'},
		{ id: '30 jours - date de réception de facture', label: '30 jours - date de réception de facture'},
		{ id: '45 jours - date de réception de facture', label: '30 jours - date de réception de facture'},
		{ id: '60 jours - date de réception de facture', label: '30 jours - date de réception de facture'},
	]

	private refIndex = [
		"ISRP", "ICHTE", "04510", "FSD3", "ICHTrev-TS1", "FM0ABE000"
	]

	private articleUnites = [
		{id: 'Tonnes de MS', label: 'Tonnes de MS'},
		{id: 'Tonnes', label: 'Tonnes'},
		{id: 'Nbre de rotations', label: 'Nbre de rotations'},
		{id: 'Nbre de colis', label: 'Nbre de colis'},
		{id: 'Heures', label: 'Heures'},
	];

	private actualisationIndex = ["Dernière valeur connue", "m-1", "m-2"];

	private nombreIndex = [1, 2, 3];

	private indexation = [
		{id: 'Annuelle', label: 'Annuelle'},
		{id: 'Mensuelle', label: 'Mensuelle'},
		{id: 'Non', label: 'Non'},
	];

	private repetitionList = [
		"Jamais",
		"Quotidienne",
		"Hebdomadaire",
		"Chaque jour ouvrable",
		"Toutes les deux semaines",
		"Mensuelle",
		"Annuelle"
	]

	private categorieDigestat = ["SPA C2.h"];

	private localisationDigestat = [
		"Site de méthanisation",
		"Stock déporté"
	]

	private typeDigestat = [
		{ id: 'Digestat brut', label: 'Digestat brut' },
		{ id: 'Digestat liquide', label: 'Digestat liquide' },
		{ id: 'Digestat solide', label: 'Digestat solide' },
		{ id: 'Digestat séché', label: 'Digestat séché' },
		{ id: 'Soupe de biodéchet', label: 'Soupe de biodéchet'},
		{ id: 'Autre produit', label: 'Autre produit' }
	]

	private typeValorisationDigestat = [
		{ id: 'R1 - Utilisé comme combustible (valorisation énergétique)', label: 'R1 - Utilisé comme combustible (valorisation énergétique)' },
		{ id: 'R3.b - Biométhanisation', label: 'R3.b - Biométhanisation' },
		{ id: 'R3.c - Compostage', label: 'R3.c - Compostage' },
		{ id: 'R5 - Recyclage inorganique', label: 'R5 - Recyclage inorganique' },
		{ id: 'R7 - Récupération de capteurs de polluants', label: 'R7 - Récupération de capteurs de polluants' },
		{ id: 'R9.a - Régénération des huiles', label: 'R9.a - Régénération des huiles' },
		{ id: 'R9.b - Autre réemploi des huiles', label: 'R9.b - Autre réemploi des huiles' },
		{ id: 'R10 - Epandage en agriculture', label: 'R10 - Epandage en agriculture' },
		{ id: 'R13 - Stockage hors site avant valorisation', label: 'R13 - Stockage hors site avant valorisation' },
		{ id: 'R12 - Echangé pour valorisation', label: 'R12 - Echangé pour valorisation' },
		{ id: 'D5 - Mis en Centre d’Enfouissement Technique', label: 'D5 - Mis en Centre d’Enfouissement Technique' },
		{ id: 'D9 - Traitement physico-chimique avant élimination', label: 'D9 - Traitement physico-chimique avant élimination' },
		{ id: 'D12 - Stockage permanent', label: 'D12 - Stockage permanent' },
		{ id: 'D13 - Regroupement avant élimination', label: 'D13 - Regroupement avant élimination' },
		{ id: 'D15 - Stockage hors site avant élimination', label: 'D15 - Stockage hors site avant élimination' },
		{ id: 'Autre', label: 'Autre' },
	];

	private typeValorisationIntrant = [
		"D5 - Mis en Centre d'Enfouissement Technique (CET)",
		"D10 - Incinéré",
		"D12 - Stockage permanent",
		"D13 - Regroupement avant élimination",
		"D14 - Reconditionnement avant élimination",
		"D15 - Stockage hors site avant élimination",
		"R1 - Utilisé comme combustible",
		"R3.a - Alimentation animale",
		"R3.b - Biométhanisation",
		"R3.c - Compostage",
		"R10 - Epandage en agriculture",
		"R12 - Echangé pour valorisation",
		"R13 - Stockage hors site avant valorisation"
	];

	private typeValorisationDechet = [
		"D5 - Mis en Centre d'Enfouissement Technique (CET)",
		"D10 - Incinéré",
		"D12 - Stockage permanent",
		"D13 - Regroupement avant élimination",
		"D14 - Reconditionnement avant élimination",
		"D15 - Stockage hors site avant élimination",
		"R1 - Utilisé comme combustible"
	]

	private typeValorisationAutre = [
		"D5 - Mis en Centre d'Enfouissement Technique (CET)",
		"D10 - Incinéré",
		"D12 - Stockage permanent",
		"D13 - Regroupement avant élimination",
		"D14 - Reconditionnement avant élimination",
		"D15 - Stockage hors site avant élimination",
		"R1 - Utilisé comme combustible",
		"R3.a - Alimentation animale",
		"R3.b - Biométhanisation",
		"R3.c - Compostage",
		"R10 - Epandage en agriculture",
		"R12 - Echangé pour valorisation",
		"R13 - Stockage hors site avant valorisation"
	]

	private utilisationPrincipale = [
		// 'R3.c - Compostage',
		// 'R10 - Epandage en agriculture',
		// 'R13 - Stockage hors site avant valorisation',
		// 'Autre'
		'R1 - Utilisé comme combustible (valorisation énergétique)',
		'R3.b - Biométhanisation',
		'R3.c - Compostage',
		'R5 - Recyclage inorganique',
		'R7 - Récupération de capteurs de polluants',
		'R9.a - Régénération des huiles',
		'R9.b - Autre réemploi des huiles',
		'R10 - Epandage en agriculture',
		'R13 - Stockage hors site avant valorisation',
		'R12 - Echangé pour valorisation',
		'D5 - Mis en Centre d’Enfouissement Technique',
		'D9 - Traitement physico-chimique avant élimination',
		'D12 - Stockage permanent',
		'D13 - Regroupement avant élimination',
		'D15 - Stockage hors site avant élimination',
		'Autre'
	];

	private typeProduit = ["Digestat", "Intrant non méthanisé", "Déchets issues de l'activité du site", "Autre"];

	private envoiFacture = [
		{id: 'Par courrier', label: 'Par courrier'},
		{id: 'Par mail', label: 'Par mail'}
	]

	private optionsSwitch = [
		{ label: 'Oui', value: true }, { label: 'Non', value: false }
	];

	private methodCalculCPC = [
		{id: "", label: ""},
		{id: "Utiliser le dernier gisement", label: "Utiliser le dernier gisement"},
		{id: "Saisie manuelle", label: "Saisie manuelle"}
	]

	private saisieValeursDefaut = [
		{ id: "Null", label: "Null", value: true },
		{ id: "Zéro", label: "Zéro", value: false }
	]

	private typesAnalyseDigestat = [
		"Biologique",
		"Agronomique",
		"Règlementaire"
	];

	private documentsAEmettre = [
		{id:"BSD", label: "BSD"},
		{id:"DAC", label: "DAC"},
		{id:"Autre", label: "Autre"},
		{id:"Aucun", label: "Aucun"}
	];

	private sousProduitAnimaux = [
		["Non applicable", ""],
		["SPA C2", ""],
		["SPA C2 (dérogatoire)", ""],
		["SPA C2 art 9", ""],
		["SPA C2 (dérogatoire) art 9", ""],
		["SPA C3", ""],
		["SPA C3-10a", "Issues d'abattoir : parties d’animaux propres à la consommation humaine"],
		["SPA C3-10bi", "Issues d'abattoir : parties d’animaux impropres à la consommation humaine"],
		["SPA C3-10bii", "Issues d'abattoir : têtes des volailles"],
		["SPA C3-10biii", "Issues d'abattoir : cuirs et peaux"],
		["SPA C3-10biv", "Issues d'abattoir : soies de porcs"],
		["SPA C3-10bv", "Issues d'abattoir : plumes"],
		["SPA C3-10c", "Volaille et lagomorphes abattus dans l’exploitation"],
		["SPA C3-10d", "Sang"],
		["SPA C3-10e", "Issues de la production de denrées (y compris boues de laiteries)"],
		["SPA C3-10f", "Anciennes denrées alimentaires"],
		["SPA C3-10g", "Anciens aliments pour animaux"],
		["SPA C3-10h ", "Issus d'animaux vivants (notamment lait cru, colostrum)"],
		["SPA C3-10i", "Parties d'animaux aquatiques"],
		["SPA C3-10j", "Issues de la production de denrées utilisant des produits aquatiques"],
		["SPA C3-10ki", "Carapaces de crustacés ou coquilles de mollusques"],
		["SPA C3-10kii", "Oeufs et coquilles"],
		["SPA C3-10kiii", "Poussins d’un jour abattus pour des raisons commerciales"],
		["SPA C3-10l", "Invertébrés aquatiques ou terrestre"],
		["SPA C3-10m", "Rongeurs et lagomorphes"],
		["SPA C3-10n", "Issues d'animaux morts : Peaux, sabots, plumes, laine, cornes, poils, …"],
		["SPA C3-10o", "Issues d'abattoir : tissus adipeux"],
		["SPA C3-10p", "Déchets de cuisine et de table"]
	];

	private consistances = ["Solide", "Liquide"];

	private consistancesGisement = [
		{id: 'Solide', label: 'Solide'},
		{id: 'Liquide', label: 'Liquide'},
		{id: 'Pateux', label: 'Pateux'},
		{id: 'Pulvérulent', label: 'Pulvérulent'},
		{id: 'Graisseux', label: 'Graisseux'}
	];

	private statusReception = [
		{id:"Informations manquantes", label: "Informations manquantes"},
		{id:"Acceptée", label: "Acceptée"},
		{id:"Refusée (non-conformité)", label: "Refusée (non-conformité)"},
		{id:"Refusée (autre)", label: "Refusée (autre)"}
	]

	private statusReceptionFacturation = [
		{id:"-", label: "-"},
		{id:"En attente", label: "En attente"},
		{id:"Rapprochée", label: "Rapprochée"},
		{id:"Clôturée", label: "Clôturée"}
	];

	private statutExport = [
		{id:"-", label: "-"},
		{id:"A exporter", label: "A exporter"},
		{id:"Exportée", label: "Exportée"}
	]

	private typeActivite = [
		{ id: "Producteur de biomasse ou de déchets", label: "Producteur de biomasse ou de déchets" },
		{ id: "Transporteur", label: "Transporteur" },
		{ id: "Négociant", label: "Négociant" },
		{ id: "Receveur de digestat", label: "Receveur de digestat" },
		{ id: "Fournisseur de matériel", label: "Fournisseur de matériel et autres produits" },
		{ id: "Acheteur d'énergie", label: "Acheteur d'énergie" },
		{ id: "Fournisseur d'énergie", label: "Fournisseur d'énergie" },
		{ id: "Autre", label: "Autre" },
		];

	private typeTiers = [
		{ id: "Client (Vente)", label: "Client (Vente)" },
		{ id: "Fournisseur (Achat)", label: "Fournisseur (Achat)" },
		{ id: "Base d'échange", label: "Base d'échange" },
		{ id: "Pas de facturation", label: "Pas de facturation" },
	];

	private typeTiersArticle = [
		"Producteur de biomasse ou de déchets",
		"Transporteur",
		"Négociant",
		"Receveur de digestat"
	];

	private typeFacturation = [
		{ id: "Client (Vente)", label: "Client (Vente)" },
		{ id: "Fournisseur (Achat)", label: "Fournisseur (Achat)" },
		{ id: "Base d'échange", label: "Base d'échange" },
		{ id: "Pas de facturation", label: "Pas de facturation" },
	];

	private indicateursRation = [
		{
			"id": "MS/MB",
			"label": "MS/MB (%)",
			"nomComplet": "Matière sèche sur matière brute",
			"infobulle": "Pourcentage de matière sèche sur la masse totale de la ration",
			"type": "cpc",
			"unit": "%",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "%",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "%",
					"infobulle": ""
				}
			]
		},
		{
			"id": "MO/MB",
			"label": "MO/MB (%)",
			"nomComplet": "Matière organique sur matière brute",
			"infobulle": "Pourcentage de matière organique sur la masse totale de la ration",
			"type": "cpc",
			"unit": "%",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "%",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "%",
					"infobulle": ""
				}
			]
		},
		{
			"id": "C/N",
			"label": "C/N",
			"nomComplet": "C/N",
			"infobulle": "Quantité de carbone par rapport à la quantité d’azote dans la ration",
			"type": "cpc",
			"unit": "-",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "",
					"infobulle": ""
				}
			]
		},
		{
			"id": "C org.",
			"label": "CO (g/kg)",
			"nomComplet": "Carbon organique",
			"infobulle": "Taux de carbone organique sur la masse totale de la ration",
			"type": "cpc",
			"unit": "g/kg",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "g/kg",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "g/kg",
					"infobulle": ""
				}
			]
		},
		{
			"id": "N total",
			"label": "N total (g/kg)",
			"nomComplet": "Azote total",
			"infobulle": "Taux d’azote total sur la masse totale de la ration",
			"type": "cpc",
			"unit": "g/kg",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "g/kg",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "g/kg",
					"infobulle": ""
				}
			]
		},
		{
			"id": "N-NH4",
			"label": "N-NH4 (g/kg)",
			"nomComplet": "Azote ammoniacal",
			"infobulle": "Taux d’azote ammoniacal sur la masse totale de la ration",
			"type": "cpc",
			"unit": "g/kg",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "g/kg",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "g/kg",
					"infobulle": ""
				}
			]
		},
		{
			"id": "P2O5",
			"label": "Phosphore (P2O5)",
			"nomComplet": "Phosphore",
			"infobulle": "Phosphore",
			"type": "cpc",
			"unit": "g/kg MB",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "g/kg MB",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "g/kg MB",
					"infobulle": ""
				}
			]
		},
		{
			"id": "K2O",
			"label": "Potassium (K2O)",
			"nomComplet": "Potassium",
			"infobulle": "Potassium",
			"type": "cpc",
			"unit": "g/kg MB",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "g/kg MB",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "g/kg MB",
					"infobulle": ""
				}
			]
		},
		{
			"id": "SO3",
			"label": "Soufre (SO3)",
			"nomComplet": "Soufre",
			"infobulle": "Soufre",
			"type": "cpc",
			"unit": "g/kg MB",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "g/kg MB",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "g/kg MB",
					"infobulle": ""
				}
			]
		},
		{
			"id": "Tonnage intrant",
			"label": "Tonnage intrant (tonne)",
			"nomComplet": "Tonnage intrant",
			"infobulle": "Tonnage journalier hors catégorie : “Eau”,“Digestat”,“Digestat SPAn”",
			"type": "divers",
			"unit": "Tonne",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "Tonne",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "Tonne",
					"infobulle": ""
				}
			]
		},
		{
			"id": "Tonnage intrant + Eau",
			"label": "Tonnage intrant + Eau (tonne)",
			"nomComplet": "Tonnage intrant + Eau",
			"infobulle": "Tonnage journalier hors catégorie : “Digestat”,“Digestat SPAn”",
			"type": "divers",
			"unit": "Tonne",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "Tonne",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "Tonne",
					"infobulle": ""
				}
			]
		},
		{
			"id": "% Effluents",
			"label": "Taux d'effluents (%)",
			"nomComplet": "Taux d’effluents d’élevage",
			"infobulle": "Pourcentage d’effluents d’élevage dans la ration calculé selon la catégorie des stocks : “Effluents d’élevage liquides” ou “Effluents d’élevage solides”",
			"type": "divers",
			"unit": "%",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "%",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "%",
					"infobulle": ""
				}
			]
		},
		{
			"id": "% Graisses",
			"label": "Taux de graisses (%)",
			"nomComplet": "Taux de graisses",
			"infobulle": "Pourcentage de graisses dans la ration calculé selon la caractéristique “matière grasse brute” des stocks",
			"type": "divers",
			"unit": "%",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "%",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "%",
					"infobulle": ""
				}
			]
		},
		{
			"id": "% Culture principale",
			"label": "Culture principale (%)",
			"nomComplet": "Taux de culture principale",
			"infobulle": "Pourcentage de matière dans la ration calculé selon la catégorie des stocks : “Culture principale”",
			"type": "divers",
			"unit": "%",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "%",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "%",
					"infobulle": ""
				}
			]
		},
		{
			"id": "% Récirculation",
			"label": "Récirculation (%)",
			"nomComplet": "Taux de Récirculation dans le digesteur",
			"type": "divers",
			"unit": "%",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "%",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "%",
					"infobulle": ""
				}
			]
		},
		{
			"id": "Charge organique",
			"label": "Charge organique (kg MO)",
			"nomComplet": "Charge organique appliquée",
			"infobulle": "Calcul de la masse de matière organique entrante par m3 de digesteur et par jour",
			"type": "divers",
			"unit": "kg MO/m3 de digesteur/jour",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "kg MO/m3/j",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "kg MO/m3/j",
					"infobulle": ""
				}
			]
		},
		{
			"id": "Temps séjour",
			"label": "Tps séjour (jours)",
			"nomComplet": "Temps de séjour digestion",
			"infobulle": "Temps de séjour de la matière en digestion",
			"type": "divers",
			"unit": "jours",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "j",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "j",
					"infobulle": ""
				},
				{
					"label": "Masse volumique",
					"value": 0.9,
					"unit": "T/m3",
					"infobulle": "Masse volumique caractérise la masse de digestat par unité de volume"
				}
			]
		},
		{
			"id": "Débit CH4",
			"label": "Débit CH4 (Nm3/h)",
			"nomComplet": "Débit potentiel CH4",
			"infobulle": "Débit de biogaz théorique maximum pendant 24h qui peut être produit par la ration",
			"type": "divers",
			"unit": "Nm3/h",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "Nm3/h",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "Nm3/h",
					"infobulle": ""
				}
			]
		},
		{
			"id": "Débit biogaz",
			"label": "Débit biogaz (Nm3/h)",
			"nomComplet": "Débit potentiel biogaz",
			"infobulle": "Débit de biogaz théorique maximum pendant 24h qui peut être produit par la ration",
			"type": "divers",
			"unit": "Nm3/h",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "Nm3/h",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "Nm3/h",
					"infobulle": ""
				},
				{
					"label": "Taux CH4 Biogaz",
					"value": 55,
					"unit": "%",
					"infobulle": "Taux de CH4 moyen constaté dans le biogaz"
				},
				{
					"label": "TPM Prévi",
					"value": 100,
					"unit": "%",
					"infobulle": "Pourcentage du potentiel méthane de la ration qui sera converti en biogaz"
				}
			]
		},
		{
			"id": "Débit biométhane",
			"label": "Débit biométhane (Nm3/h)",
			"nomComplet": "Débit potentiel biométhane",
			"infobulle": "Débit de biométhane théorique maximum pendant 24h qui peut être produit par la ration",
			"type": "divers",
			"unit": "Nm3/h",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "Nm3/h",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "Nm3/h",
					"infobulle": ""
				},
				{
					"label": "Taux CH4 biométhane",
					"value": 98,
					"unit": "%",
					"infobulle": "Taux de CH4 moyen constaté dans le biométhane"
				},
				{
					"label": "Rendement épuratoire",
					"value": 100,
					"unit": "%",
					"infobulle": "Rendement épuratoire"
				},
				{
					"label": "TPM Prévi",
					"value": 100,
					"unit": "%",
					"infobulle": "Pourcentage du potentiel méthane de la ration qui sera converti en biogaz"
				}

			]
		},
		{
			"id": "Puissance élec",
			"label": "Puissance élec (kW)",
			"nomComplet": "Puissance électrique potentielle",
			"infobulle": "Puissance électrique théorique maximum pendant 24h qui peut être produite par la ration",
			"type": "divers",
			"unit": "kW",
			"constantes": [
				{
					"label": "Seuil min",
					"value": null,
					"unit": "kW",
					"infobulle": ""
				},
				{
					"label": "Seuil max",
					"value": null,
					"unit": "kW",
					"infobulle": ""
				},
				{
					"label": "Rendement cogé",
					"value": 40,
					"unit": "%",
					"infobulle": "Rendement cogé"
				},
				{
					"label": "TPM Prévi",
					"value": 100,
					"unit": "%",
					"infobulle": "Pourcentage du potentiel méthane de la ration qui sera converti en biogaz"
				}
			]
		}
	]


	private categoriesMatiere = [
		{id: "Eau", label:"Eau"},
		{id:"Effluents d'élevage solides", label:"Effluents d'élevage solides"},
		{id:"Effluents d'élevage liquides", label:"Effluents d'élevage liquides"},
		{id: "Cultures principales", label:"Cultures principales"},
		{id:"Cultures intermédiaires", label:"Cultures intermédiaires"},
		{id:"Résidus de cultures", label:"Résidus de cultures"},
		{id:"Déchets IAA d'origine animale", label:"Déchets IAA d'origine animale"},
		{id:"Déchets IAA d'origine végétale", label:"Déchets IAA d'origine végétale"},
		{id:"Digestat", label:"Digestat"},
		{id:"Digestat SPAn", label:"Digestat SPAn"},
		{id:"Biodéchets d'origine végétale", label:"Biodéchets d'origine végétale"},
		{id:"Biodéchets d'origine animale", label:"Biodéchets d'origine animale"},
		{id:"Biodéchets autres", label:"Biodéchets autres"},
		{id:"Boues de STEP IAA", label:"Boues de STEP IAA"},
		{id:"Boues de STEP Urbaine", label:"Boues de STEP Urbaine"},
		{id:"Ordures ménagères", label:"Ordures ménagères"},
		{id:"Déchets verts", label:"Déchets verts"},
		{id:"Autres", label:"Autres"}
	];

	private categoriesRed2 = [
		{ id: "Déchet résidus IAA", label: "Déchet / résidus IAA"},
		{id: "Co-produit produit IAA", label: "Co-produit / produit IAA"},
		{id: "Co-produit produit Agricole", label: "Co-produit / produit Agricole"},
		{id: "Résidus Agricoles", label: "Résidus Agricoles"},
		{id: "Déchets Agricoles", label: "Déchets Agricoles"}
	]

	private typesGisement = [
		{id:"Agricole", label:"Agricole"},
		{id:"Industriel", label:"Industriel"},
		{id:"Collectivité", label:"Collectivité"}
	];
	private listGroupCategoriesMatiere = [
		{
			"label": "Agricole",
			"options": [
				"Cultures principales",
				"Cultures intermédiaires",
				"Résidus de cultures",
				"Effluents d'élevage solides",
				"Effluents d'élevage liquides",
			]
		},
		{
			"label": "Industrie",
			"options": [
				"Déchets IAA d'origine végétale",
				"Déchets IAA d'origine animale",
				"Boues de STEP IAA",
			]
		},
		{
			"label": "Collectivité/GMS/Autre",
			"options": [
				"Déchets verts",
				"Boues de STEP Urbaine",
				"Biodéchets d'origine végétale",
				"Biodéchets d'origine animale",
				"Biodéchets autres",
				"Ordures ménagères",
				"Autres"
			]
		},{
			"label": "Autres",
			"options": [
				"Eau",
				"Digestat",
				"Digestat SPAn",
			]
		}

	];

	private durabilitesGisement = [
		{id: "Durable", label: "Durable"},
		{id: "Non certifié", label: "Non certifié"}
	];

	private certificationsGisement = [
		{id: "Aucune", label: "Aucune"},
		{id: "Certifié par un Voluntary Scheme", label: "Certifié par un Voluntary Scheme"},
		{id: "Self-declaration", label: "Self-declaration"},
		{id: "Autre", label: "Autre"}
	];

	private modesDeLivraison = [
		{id:"BENNE", label: "BENNE"},
		{id:"BENNE CEREALIERE 60M3", label: "BENNE CEREALIERE 60M3"},
		{id:"BENNE CEREALIERE 90M3", label: "BENNE CEREALIERE 90M3"},
		{id:"CAISSON 10M3", label: "CAISSON 10M3"},
		{id:"CAISSON 13M3", label: "CAISSON 13M3"},
		{id:"CAISSON 17M3", label: "CAISSON 17M3"},
		{id:"CAISSON 20M3", label: "CAISSON 20M3"},
		{id:"CAISSON 25M3", label: "CAISSON 25M3"},
		{id:"CAISSON 30M3", label: "CAISSON 30M3"},
		{id:"CAISSON 6M3", label: "CAISSON 6M3"},
		{id:"CAISSON ETANCHE", label: "CAISSON ETANCHE"},
		{id:"CAISSON INOX", label: "CAISSON INOX"},
		{id:"CITERNE", label: "CITERNE"},
		{id:"CITERNE + POMPE", label: "CITERNE + POMPE"},
		{id:"CITERNE 25T", label: "CITERNE 25T"},
		{id:"CITERNE 28T", label: "CITERNE 28T"},
		{id:"CITERNE 30T", label: "CITERNE 30T"},
		{id:"EPANDEUR HERISSON", label: "EPANDEUR HERISSON"},
		{id:"EPANDEUR TABLE", label: "EPANDEUR TABLE"},
		{id:"FOND MOUVANT", label: "FOND MOUVANT"},
		{id:"FOND POUSSANT", label: "FOND POUSSANT"},
		{id:"HYDROCUREUR 12T", label: "HYDROCUREUR 12T"},
		{id:"HYDROCUREUR 7T", label: "HYDROCUREUR 7T"},
		{id:"PLATEAU", label: "PLATEAU"},
		{id:"POMPE", label: "POMPE"},
		{id:"REMORQUE BENNE", label: "REMORQUE BENNE"},
		{id:"TONNE", label: "TONNE"},
		{id:"AUTRE", label: "AUTRE"},
		{id:"TAUTLINER", label: "TAUTLINER"},
		{id:"PORTEUR", label: "PORTEUR"},
		{id:"SEMI AMPLIROL", label: "SEMI AMPLIROL"},
		{id:"CAISSON 35M3", label: "CAISSON 35M3"},
		{id: "TONNE 25m3 AVEC PENDILLARD", label: "TONNE 25m3 AVEC PENDILLARD"},
		{ id : "TRACTEUR BENNE", label : "TRACTEUR BENNE" },
		{ id : "TRACTEUR TONNE", label : "TRACTEUR TONNE" },
		{ id : "TRACTEUR TONNE + PENDILLARD", label : "TRACTEUR TONNE + PENDILLARD" },
		{ id : "SEMI REMORQUE", label : "SEMI REMORQUE" },
	];

	private conditionnements = [
		{id: "VRAC LIQUIDE", label: "VRAC LIQUIDE"},
		{id: "VRAC SOLIDE", label: "VRAC SOLIDE"},
		{id: "GRV / IBC 1000L", label: "GRV / IBC 1000L"},
		{id: "FUT", label: "FUT"},
		{id: "BIG BAG", label: "BIG BAG"},
		{id: "PALETTE 80*120 EUR", label: "PALETTE 80*120 EUR"},
		{id: "PALETTE 80*120 PERDUE", label: "PALETTE 80*120 PERDUE"},
		{id: "PALETTE 100*120", label: "PALETTE 100*120"},
		{id: "GEOBOX 80*120", label: "GEOBOX 80*120"},
		{id: "GEOBOX 100*120", label: "GEOBOX 100*120"},
		{id: "BACS", label: "BACS"},
	];

	private codesDechets = [
		["00 00 00", "Sans objet"],
		["02 01 01", "Boues provenant du lavage et du nettoyage"],
		["02 03 01", "Boues provenant du lavage, du nettoyage, de l'épluchage, de la centrifugation et de la séparation"],
		["02 04 03", "Boues provenant du traitement in situ des effluents"],
		["02 06 01", "Matières impropres à la consommation ou à la transformation"],
		["02 06 03", "Boues provenant du traitement in situ des effluents de boulangerie et confiserie"],
		["02 01 06", "Effluents d'élevages"],
		["02 01 03", "Déchets de tissus végétaux issus de l'agriculture"],
		["02 02 02", "Déchets IAA d'origine animale : déchets de tissus animaux"],
		["02 02 03", "Déchets IAA d'origine animale : matières impropres à la consommation ou à la transformation"],
		["02 03 05", "Boues provenant du traitement in situ de déchets IAA végétaux"],
		["02 02 04", "Déchets IAA d'origine animale : boues provenant du traitement in situ des effluents"],
		["02 02 99", "Déchets non spécifiés ailleurs"],
		["02 03 04", "Déchets IAA végétaux (sauf sucre, alcool) - matière impropre à la conso"],
		["02 05 02", "Boues provenant du traitement in situ des effluents"],
		["02 06 99", "Déchets non spécifiés ailleurs"],
		["02 05 01", "Déchets d'industrie lait"],
		["02 07 01", "Déchets provenant du lavage, du nettoyage et de la réduction mécanique des matières premières"],
		["02 07 04", "Déchets d'industrie de l'alcool"],
		["02 07 05", "Boues provenant du traitement in situ des effluents"],
		["02 07 99", "Déchets non spécifiés ailleurs"],
		["19 08 01", "Déchets de dégrillage"],
		["19 08 05", "Eaux usées et boues de STEP"],
		["19 08 09", "Mélanges de graisse et d'huile provenant de la séparation huile/eaux usées contenant seulement des huiles et graisses alimentaires"],
		["20 01 08", "Biodéchets"],
		["20 02 01", "Déchets verts fermentescibles"],
		["20 03 02", "Déchets de marchés"],
		["20 01 25", "Huiles et matières grasses alimentaires"],
		["03 03 11", "Boues de papeteries"],
		["19 05 03", "Compost déclassé"],
		["19 06 06", "Digestats de méthanisation"],
		["19 08 12", "Boues provenant du traitement biologique des eaux usées industrielles autres que celles visées à la rubrique 19 08 11"],
		["19 09 99", "Déchets non spécifiés ailleurs"],
		["02 03 99", "Autres déchets IAA végétaux (sauf sucre, alcool)"],
		["02 04 99", "Autres déchets issus de l'industrie du sucre"],
		["03 01 05", "Sciure de bois, copeaux, chutes, bois, panneaux de particules et placages autres que ceux visés à la rubrique 03 01 04"],
		["16 03 06", "Déchets d'origine organique autres que ceux visés à la rubrique 16 03 05"],
		["20 01 99", "Autres fractions non spécifiées ailleurs"],
		["07 01 99", "Déchets non spécifiés ailleurs"],
		["19 12 12", "Autres déchets (y compris mélanges) provenant du traitement mécanique des déchets autres que ceux visés à la rubrique 19 12 11"],
		["02 04 01", "Terre provenant du lavage et du nettoyage des betteraves"],
		["02 01 02", "Déchets de tissus animaux"],
		["02 05 99", "Déchets non spécifiés ailleurs"]
	];

	private nomFamille = {
		"ABS": "Absolu",
		"PPM": "Concentration gaz",
		"CL": "Concentration liquide",
		"CM": "Concentration massique",
		"CND": "Conductivité",
		"CSP": "Consommation spécifique",
		"A": "Courant",
		"QM": "Débit massique",
		"QV": "Débit volumique",
		"QVG": "Débit volumique gaz",
		"LEVEL": "Distance",
		"EURO": "Euro",
		"F": "Fréquence",
		"M": "Masse",
		"PG": "Potentiel gaz",
		"PCM": "Pouvoir calorifique massique",
		"PCV": "Pouvoir calorifique volumique",
		"PCVG": "Pouvoir calorifique volumique gaz",
		"P": "Pression",
		"PWRACT": "Puissance active",
		"PWRAPP": "Puissance apparente",
		"PWRREAC": "Puissance réactive",
		"E": "Quantité d'énergie",
		"S": "Surface",
		"RATE": "Taux",
		"T": "Température",
		"TIME": "Temps",
		"U": "Tension",
		"V": "Volume",
		"VG": "Volume gaz",
		"GES": "Emission de GES"
	}

	private nomBaseFamille = {
		"ABS": "ABS",
		"PPM": "PPM",
		"CL": "CL",
		"CM": "CM",
		"CND": "CND",
		"CSP": "CSP",
		"A": "A",
		"QM": "QM",
		"QV": "QV",
		"QVG": "QVG",
		"LEVEL": "LEVEL",
		"EURO": "EURO",
		"F": "F",
		"M": "M",
		"PG": "PG",
		"PCM": "PCM",
		"PCV": "PCV",
		"PCVG": "PCVG",
		"P": "P",
		"PWRACT": "PWRACT",
		"PWRAPP": "PWRAPP",
		"PWRREAC": "PWRREAC",
		"E": "E",
		"S": "S",
		"RATE": "RATE",
		"T": "T",
		"TIME": "TIME",
		"U": "U",
		"V": "V",
		"VG": "VG",
		"GES": "GES"
	}

	private unitesFamille = {
		"ABS": [{ label: "-", baseRatio: 1, id: 10, idFamille: 23, idFamilleIndex: 23 }],
		"PPM": [{ label: "ppm", baseRatio: 1, id: 20, idFamille: 159 }],
		"CL": [{ label: "mg/L", baseRatio: 1, id: 30, idFamille: 585 }, { label: "kg/m3", baseRatio: 1000, id: 40, idFamille: 585 }, { label: "g/L", baseRatio: 1000, id: 31, idFamille: 585 }],
		"CM": [{ label: "mg/kg", baseRatio: 1, id: 50, idFamille: 140 }, { label: "kg/tonne", baseRatio: 1000, id: 60, idFamille: 140 }],
		"A": [{ label: "A", baseRatio: 1, id: 520, idFamille: 4 }, { label: "mA", baseRatio: 1000, id: 510, idFamille: 4 }],
		"QM": [{ label: "kg/h", baseRatio: 60, id: 70, idFamille: 586 }, { label: "tonne/h", baseRatio: 1000 / 60, id: 80, idFamille: 558 }],
		"QV": [{ label: "m3/h", baseRatio: 60, id: 90, idFamille: 162 }, { label: "L/h", baseRatio: 1000 / 60, id: 100, idFamille: 517 }],
		"QVG": [{ label: "Nm3/h", baseRatio: 60, id: 110, idFamille: 587 }],
		"LEVEL": [{ label: "m", baseRatio: 1, id: 120, idFamille: 588, idFamilleIndex: 588 }, { label: "cm", baseRatio: 100, id: 130, idFamille: 588, idFamilleIndex: 588 }],
		"EURO": [{ label: "€", baseRatio: 1, id: 140, idFamille: 589 }],
		"F": [{ label: "Hz", baseRatio: 1, id: 150, idFamille: 171 }],
		"M": [{ label: "kg", baseRatio: 1, id: 160, idFamille: 590, idFamilleIndex: 590 }, { label: "tonnes", baseRatio: 1000, id: 170, idFamille: 820, idFamilleIndex: 820 }],
		"PG": [{ label: "Nm3/tonne", baseRatio: 1, id: 180, idFamille: 598 }],
		"RATE": [{ label: "%", baseRatio: 1, id: 190, idFamille: 18 }],
		"PCM": [{ label: "kWh/tonne", baseRatio: 1000, id: 200, idFamille: 592 }, { label: "Wh/tonne", baseRatio: 1, id: 210, idFamille: 592 }],
		"PCV": [{ label: "kWh/m3", baseRatio: 1000, id: 220, idFamille: 593 }, { label: "Wh/m3", baseRatio: 1, id: 230, idFamille: 593 }],
		"PCVG": [{ label: "kWh/Nm3", baseRatio: 1000, id: 240, idFamille: 202 }, { label: "Wh/Nm3", baseRatio: 1, id: 250, idFamille: 202 }],
		"P": [{ label: "mbar", baseRatio: 100, id: 260, idFamille: 518 }, { label: "bar", baseRatio: 100000, id: 270, idFamille: 164 }, { label: "barg", baseRatio: 100000, id: 570, idFamille: 164 }],
		"PWRACT": [{ label: "W", baseRatio: 1, id: 280, idFamille: 264 }, { label: "kW", baseRatio: 1000, id: 290, idFamille: 264 }],
		"PWRAPP": [{ label: "VA", baseRatio: 1, id: 300, idFamille: 62 }, { label: "kVA", baseRatio: 1000, id: 310, idFamille: 62 }],
		"PWRREAC": [{ label: "VAR", baseRatio: 1, id: 320, idFamille: 63 }, { label: "kVAR", baseRatio: 1000, id: 330, idFamille: 63 }],
		"E": [{ label: "Wh", baseRatio: 1, id: 340, idFamille: 594, idFamilleIndex: 138 }, { label: "kWh", baseRatio: 1000, id: 350, idFamille: 594, idFamilleIndex: 138 }, { label: "MWh", baseRatio: 1000000, id: 360, idFamille: 594, idFamilleIndex: 138 }, { label: "GWh", baseRatio: 1000000000, id: 370, idFamille: 594, idFamilleIndex: 138 }, { label: "kJ", baseRatio: 1 / 3.6, id: 380, idFamille: 594, idFamilleIndex: 138 }, { label: "MJ", baseRatio: 1 / 0.0036, id: 390, idFamille: 594, idFamilleIndex: 138 }, { label: "GJ", baseRatio: 1 / 0.0000036, id: 400, idFamille: 594, idFamilleIndex: 138 }],
		"S": [{ label: "m2", baseRatio: 1, id: 410, idFamille: 595 }, { label: "cm2", baseRatio: 0.00001, id: 420, idFamille: 595 }],
		"T": [{ label: "°C", baseRatio: 1, id: 430, idFamille: 2 }],
		"TIME": [{ label: "min", baseRatio: 1, id: 440, idFamille: 596, idFamilleIndex: 596 }, { label: "h", baseRatio: 60, id: 450, idFamille: 163, idFamilleIndex: 163 }, { label: "j", baseRatio: 1440, id: 451, idFamille: 23 }],
		"U": [{ label: "V", baseRatio: 1, id: 460, idFamille: 500 }, { label: "kV", baseRatio: 1000, id: 470, idFamille: 500 }, { label: "mV", baseRatio: 0.001, id: 580, idFamille: 500 }],
		"V": [{ label: "l", baseRatio: 0.001, id: 480, idFamille: 23, idFamilleIndex: 561 }, { label: "m3", baseRatio: 1, id: 490, idFamille: 130, idFamilleIndex: 561 }],
		"VG": [{ label: "Nm3", baseRatio: 1, id: 500, idFamille: 597, idFamilleIndex: 504 }],
		"GES": [{ label: "g CO2/T", baseRatio: 1, id: 530, idFamille: 597, idFamilleIndex: 504 }, { label: "kg CO2", baseRatio: 1, id: 540, idFamille: 597, idFamilleIndex: 504 }, { label: "g COSeq/MJ", baseRatio: 1, id: 590, idFamille: 597, idFamilleIndex: 504 }],
		"CND": [{ label: "mS/cm", baseRatio: 1, id: 550, idFamille: 599}],
		"CSP": [{ label: "kWh/Nm3", baseRatio: 1000, id: 560, idFamille: 600}]
	}

	private units = {
		'MS/MB': { id: '%', label: '%' },
		'MO/MB': { id: '%', label: '%' },
		'MO/MS': { id: '%', label: '%' },
		'MM/MS': { id: '%', label: '%' },
		'MM/MB': { id: '%', label: '%' },
		'pH': { id: '-', label: '-' },
		'Conductivite': { id: 'mS/cm', label: 'mS/cm' },
		'Red/Ox': { id: 'mV', label: 'mV' },
		'Densite': { id: 'kg/L', label: 'kg/L' },
		'PM/MB flash': { id: 'Nm3/T MB', label: 'Nm3/T MB' },
		'PB/MB flash': { id: 'Nm3/T MB', label: 'Nm3/T MB' },
		'PM/MO flash': { id: 'Nm3/T MO', label: 'Nm3/T MO' },
		'PB/MO flash': { id: 'Nm3/T MO', label: 'Nm3/T MO' },
		'%CH4 flash': { id: '%', label: '%' },
		'PM/MB stand': { id: 'Nm3/T MB', label: 'Nm3 CH4/T MB' },
		'PB/MB stand': { id: 'Nm3/T MB', label: 'Nm3/T MB' },
		'PM/MO stand': { id: 'Nm3/T MO', label: 'Nm3 CH4/T MO' },
		'PB/MO stand': { id: 'Nm3/T MO', label: 'Nm3/T MO' },
		'PM/MS stand': { id: 'Nm3/T MS', label: 'Nm3 CH4/T MS' },
		'%CH4 stand': { id: '%', label: '%' },
		'nb jour pour 80%BMP': { id: 'j', label: 'j' },
		'Norg': { id: 'g/kg MB', label: 'g/kg MB' },
		'Ntot': { id: 'g/kg MB', label: 'g/kg MB' },
		'N': { id: 'g/kg MS', label: 'g/kg MS' },
		'Nkjeldahl': { id: 'g/kg MB', label: 'g/kg MB' },
		'P2O5': { id: 'g/kg MB', label: 'g/kg MB' },
		'K2O': { id: 'g/kg MB', label: 'g/kg MB' },
		'C org.': { id: 'g/kg MB', label: 'g/kg MB' },
		'C/N': { id: '-', label: '-' },
		'N-NH4': { id: 'g/kg MB', label: 'g/kg MB' },
		'N-NH3': { id: 'g/kg MB', label: 'g/kg MB' },
		'N-NO3': { id: 'g/kg MB', label: 'g/kg MB' },
		'N-NO2': { id: 'g/kgMB', label: 'g/kgMB' },
		'Azote ureique': { id: 'g/kgMB', label: 'g/kgMB' },
		'CaO': { id: 'g/kg MB', label: 'g/kg MB' },
		'MgO': { id: 'g/kg MB', label: 'g/kg MB' },
		'Na2O': { id: 'g/kg MB', label: 'g/kg MB' },
		'SO3': { id: 'g/kg MB', label: 'g/kg MB' },
		'Chlorures': { id: 'g/kg MB', label: 'g/kg MB' },
		'CMC': { id: '-', label: '-' },
		'CMN': { id: '-', label: '-' },
		'ISMO': { id: '%MO', label: '%MO' },
		'Cellulose brute': { id: '%MO', label: '%MO' },
		'Fraction soluble': { id: '%MO', label: '%MO' },
		'Hemicellulose brute': { id: '%MO', label: '%MO' },
		'Lignine brute': { id: '%MO', label: '%MO' },
		'Cendre totale': { id: '%', label: '%' },
		'Digestibilite de la MO': { id: '%', label: '%' },
		'Extractif non azoté réel': { id: '%', label: '%' },
		'Matiere grasse brute': { id: '%', label: '%' },
		'Matiere organique indigeree': { id: '%', label: '%' },
		'Proteine brute': { id: '%', label: '%' },
		'Proteine brute reelle': { id: '%', label: '%' },
		'Glucide brut': { id: '%', label: '%' },
		'B': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Al': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Cl': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Si': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Fe': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Mn': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Mo': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Co': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Cd': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Cr': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Cu': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Hg': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Ni': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Pb': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Zn': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'P': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'K': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Ca': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Mg': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Na': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'S': { id: 'mg/kg MB', label: 'mg/kg MB' },
		'Se': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'As': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'Cr+Cu+Ni+Zn': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'Cd-MS': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'Cr-MS': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'Cr VI-MS': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'Cu-MS': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'Hg-MS': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'Ni-MS': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'Pb-MS': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'Zn-MS': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'C2': { id: 'mg/kg', label: 'mg/kg' },
		'C3': { id: 'mg/kg', label: 'mg/kg' },
		'iC4': { id: 'mg/kg', label: 'mg/kg' },
		'C4': { id: 'mg/kg', label: 'mg/kg' },
		'iC5': { id: 'mg/kg', label: 'mg/kg' },
		'C5': { id: 'mg/kg', label: 'mg/kg' },
		'C6': { id: 'mg/kg', label: 'mg/kg' },
		'AGV': { id: 'mg/L', label: 'mg/L' },
		'TAC': { id: 'mg/L', label: 'mg/L' },
		'FOS': { id: 'mg/L', label: 'mg/L' },
		'FOSTAC': { id: '-', label: '-' },
		'DCO tot': { id: 'mg/L', label: 'mg/L' },
		'DCO soluble': { id: 'mg/L', label: 'mg/L' },
		'Salmonelles-MS': { id: 'germes/g MS', label: 'germes/g MS' },
		'Salmonelles-25gMS': { id: 'germes/25g MS', label: 'germes/25g MS' },
		'Salmonelles': { id: 'germes/g MB', label: 'germes/g MB' },
		'Salmonelles num1': { id: 'germes/25g MB', label: 'germes/25g MB' },
		'Salmonelles num2': { id: 'germes/25g MB', label: 'germes/25g MB' },
		'Salmonelles num3': { id: 'germes/25g MB', label: 'germes/25g MB' },
		'Salmonelles num4': { id: 'germes/25g MB', label: 'germes/25g MB' },
		'Salmonelles num5': { id: 'germes/25g MB', label: 'germes/25g MB' },
		'Salmonelles-10gMS': { id: 'germes/ 10g MS', label: 'germes/ 10g MS' },
		'Helminthes-MS': { id: 'germes/g MS', label: 'germes/g MS' },
		'Helminthes-1.5g': { id: 'germes/1,5g MB', label: 'germes/1,5g MB' },
		'Helminthes-10gMS': { id: 'germes/ 10g MS', label: 'germes/ 10g MS' },
		'Germes-MS': { id: 'germes/g MS', label: 'germes/g MS' },
		'Coliformes-MS': { id: 'germes/g MS', label: 'germes/g MS' },
		'E.coli num1': { id: 'germes/g MB', label: 'germes/g MB' },
		'E.coli num2': { id: 'germes/g MB', label: 'germes/g MB' },
		'E.coli num3': { id: 'germes/g MB', label: 'germes/g MB' },
		'E.coli num4': { id: 'germes/g MB', label: 'germes/g MB' },
		'E.coli num5': { id: 'germes/g MB', label: 'germes/g MB' },
		'E.coli-MS': { id: 'germes/g MS', label: 'germes/g MS' },
		'Enterocoques': { id: 'germes/g MB', label: 'germes/g MB' },
		'Spores-MS': { id: 'germes/g MS', label: 'germes/g MS' },
		'Enterovirus-10gMS': { id: 'germes/ 10g MS', label: 'germes/ 10g MS' },
		'Clostridium': { id: 'germes/g MB', label: 'germes/g MB' },
		'Staphylocoques': { id: 'germes/g MB', label: 'germes/g MB' },
		'Listeria': { id: 'germes/25g MB', label: 'germes/25g MB' },
		'Nematodes larves': { id: 'germes/g MB', label: 'germes/g MB' },
		'Nematodes oeufs': { id: 'germes/g MB', label: 'germes/g MB' },
		'Levures et moisissures': { id: 'germes/g MB', label: 'germes/g MB' },
		'Aspergillus': { id: 'germes/g MB', label: 'germes/g MB' },
		'Pythium': { id: 'germes/g MB', label: 'germes/g MB' },
		'C16H10': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'C20H12(b)': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'C20H12(a)': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'PCB 28': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'PCB 101': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'PCB 118': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'PCB 138': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'PCB 153': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'PCB 180': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'PCB 52': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'PCB tot': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'HAP16': { id: 'mg/kg MS', label: 'mg/kg MS' },
		'Inertes>2mm': { id: 'g/kg MS', label: 'g/kg MS' },
		'CEMAGREFF': { id: '-', label: '-' },
		'Azote Kjeldahl': { id:  'g/kg MB', label:  'g/kg MB' },
		'Nitrite': { id: 'g/kgMB', label: 'g/kgMB' },
		'Azote Uréique': { id:  'g/kgMB', label:  'g/kgMB' },
		'Lgnine brute': { id:  '%MO', label:  '%MO' },
		'Salmonelles-MS 25g': { id: 'germes/25g MS', label: 'germes/25g MS' },
		'Helminthes-1.5gMS': { id: 'germes/1,5g MB', label: 'germes/1,5g MB' },
		'Escherichia coli n°1': { id: 'germes/g MB', label: 'germes/g MB' },
		'Escherichia coli n°2': { id: 'germes/g MB', label: 'germes/g MB' },
		'Escherichia coli n°3': { id: 'germes/g MB', label: 'germes/g MB' },
		'Escherichia coli n°4': { id: 'germes/g MB', label: 'germes/g MB' },
		'Escherichia coli n°5': { id: 'germes/g MB', label: 'germes/g MB' },
		'Entérovirus': { id: 'germes/ 10g MS', label: 'germes/ 10g MS' },
		'Staphylococcus aureus': { id: 'germes/g MB', label: 'germes/g MB' },
		'Listeria monocytogenes': { id: 'germes/25g MB', label: 'germes/25g MB' },
		'Nematodes œufs': { id: 'germes/g MB', label: 'germes/g MB' },
		'Nombre jour pour atteindre 80%BMP': { id: 'j', label: 'j' },
		'Clostridium perfringens': { id: 'germes/g MB', label: 'germes/g MB' },
		'HAP16 totaux': { id:  'mg/kg MS', label:  'mg/kg MS' },
	}

	private analyseFieldLabels = {
		/*'MS/MB' : { id: 'Matière sèche sur matière brute', label: 'Matière sèche sur matière brute' },
		'MO/MB' : { id: 'Matière organique sur matière brute', label: 'Matière organique sur matière brute' },
		'MO/MS' : { id: 'Matière organique sur matière sèche', label: 'Matière organique sur matière sèche' },
		'MM/MS' : { id: 'Matière minérale sur matière sèche', label: 'Matière minérale sur matière sèche' },
		'MM/MB' : { id: 'Matière minérale sur matière brute', label: 'Matière minérale sur matière brute' },
		'pH' : { id: 'pH à 25°C', label: 'pH à 25°C' },
		'Conductivite' : { id: 'Conductivité', label: 'Conductivité' },
		'Red/Ox' : { id: 'Potentiel Red/Ox', label: 'Potentiel Red/Ox' },
		'Densite' : { id: 'Densité', label: 'Densité' },
		'PM/MB flash' : { id: 'Potentiel méthane par tonne de matière brute - FlashBMP', label: 'Potentiel méthane par tonne de matière brute - FlashBMP' },
		'PB/MB flash' : { id: 'Potentiel biogaz par tonne de matière brute - FlashBMP', label: 'Potentiel biogaz par tonne de matière brute - FlashBMP' },
		'PM/MO flash' : { id: 'Potentiel méthane par tonne de matière organique - FlashBMP', label: 'Potentiel méthane par tonne de matière organique - FlashBMP' },
		'PB/MO flash' : { id: 'Potentiel biogaz par tonne de matière organique - flashBMP', label: 'Potentiel biogaz par tonne de matière organique - flashBMP' },
		'%CH4 flash' : { id: 'Taux de CH4 - flash', label: 'Taux de CH4 - flash' },
		'PM/MB stand' : { id: 'Potentiel méthane par tonne de matière brute', label: 'Potentiel méthane par tonne de matière brute' },
		'PB/MB stand' : { id: 'Potentiel biogaz par tonne de matière brute', label: 'Potentiel biogaz par tonne de matière brute' },
		'PM/MO stand' : { id: 'Potentiel méthane par tonne de matière organique', label: 'Potentiel méthane par tonne de matière organique' },
		'PB/MO stand' : { id: 'Potentiel biogaz par tonne de matière organique', label: 'Potentiel biogaz par tonne de matière organique' },
		 */
		"MS/MB":"Matière sèche sur matière brute",
		"MO/MB":"Matière organique sur matière brute",
		"MO/MS":"Matière organique sur matière sèche",
		"MM/MS":"Matière minérale sur matière sèche",
		"MM/MB":"Matière minérale sur matière brute",
		"pH":"pH à 25°C",
		"Conductivite":"Conductivité",
		"Red/Ox":"Potentiel Red/Ox",
		"Densite":"Densité",
		"PM/MB flash":"Potentiel méthane par tonne de matière brute - FlashBMP",
		"PB/MB flash":"Potentiel biogaz par tonne de matière brute - FlashBMP",
		"PM/MO flash":"Potentiel méthane par tonne de matière organique - FlashBMP",
		"PB/MO flash":"Potentiel biogaz par tonne de matière organique - flashBMP",
		"%CH4 flash":"Taux de CH4 - flash",
		"PM/MB stand":"Potentiel méthane par tonne de matière brute",
		"PB/MB stand":"Potentiel biogaz par tonne de matière brute",
		"PM/MO stand":"Potentiel méthane par tonne de matière organique",
		"PB/MO stand":"Potentiel biogaz par tonne de matière organique",
		"PM/MS stand":"Potentiel méthane par tonne de matière sèche",
		"%CH4 stand":"Taux de CH4",
		"nb jour pour 80%BMP":"Nombre jour pour atteindre 80% du BMP",
		"Norg":"Azote organique (N)",
		"Ntot":"Azote totale (N)",
		"N":"Azote (N)",
		"Nkjeldahl":"Azote Kjeldahl (N)",
		"P2O5":"Phosphore (P2O5)",
		"K2O":"Potassium (K2O)",
		"C org.":"Carbone Organique",
		"C/N":"C/N",
		"N-NH4":"Azote ammoniacal (N-NH4)",
		"N-NH3":"Azote ammoniacal non-ionique (N-NH3)",
		"N-NO3":"Azote nitrique (N-NO3)",
		"N-NO2":"Nitrite (NO2)",
		"Azote ureique":"Azote uréique",
		"CaO":"Calcium (CaO)",
		"MgO":"Magnésium (MgO)",
		"Na2O":"Sodium (Na2O)",
		"SO3":"Soufre (SO3)",
		"Chlorures":"Chlorures",
		"CMC":"Cycle de Minéralisation du Carbone",
		"CMN":"Cycle de Minéralisation de l'Azote",
		"ISMO":"Indice de Stabilité de la Matière Organique",
		"Cellulose brute":"Cellulose brute",
		"Fraction soluble":"Fraction soluble",
		"Hemicellulose brute":"Hemicellulose brute",
		"Lignine brute":"Lignine brute",
		"Cendre totale":"Cendre totale",
		"Digestibilite de la MO":"Digestibilité de la MO",
		"Extractif non azoté réel":"Extractif non azoté réel",
		"Matiere grasse brute":"Matière grasse brute",
		"Matiere organique indigeree":"Matière organique indigérée",
		"Proteine brute":"Protéine brute",
		"Proteine brute reelle":"Protéine brute réelle",
		"Glucide brut":"Glucide brut",
		"B":"Bore (B) (mg/kg MB)",
		"Al":"Aluminium (Al) (mg/kg MB)",
		"Cl":"Chlore (Cl) (mg/kg MB)",
		"Si":"Silicium (Si) (mg/kg MB)",
		"Fe":"Fer (Fe) (mg/kg MB)",
		"Mn":"Manganèse (Mn) (mg/kg MB)",
		"Mo":"Molybdène (Mo) (mg/kg MB)",
		"Co":"Cobalt (Co) (mg/kg MB)",
		"Cd":"Cadmium (Cd) (mg/kg MB)",
		"Cr":"Chrome total (Cr) (mg/kg MB)",
		"Cu":"Cuivre (Cu) (mg/kg MB)",
		"Hg":"Mercure (Hg) (mg/kg MB)",
		"Ni":"Nickel (Ni) (mg/kg MB)",
		"Pb":"Plomb (Pb) (mg/kg MB)",
		"Zn":"Zinc (Zn) (mg/kg MB)",
		"P":"Phosphore (P) (mg/kg MB)",
		"K":"Potassium (K) (mg/kg MB)",
		"Ca":"Calcium (Ca) (mg/kg MB)",
		"Mg":"Magnésium (Mg) (mg/kg MB)",
		"Na":"Sodium (Na) (mg/kg MB)",
		"S":"Soufre (S) (mg/kg MB)",
		"Se":"Selenium (Se) (mg/kg MS)",
		"As":"Arsenic (As) (mg/kg MS)",
		"Cr+Cu+Ni+Zn":"Cr+Cu+Ni+Zn (mg/kg MS)",
		"Cd-MS":"Cadmium (Cd) (mg/kg MS)",
		"Cr-MS":"Chrome total (Cr) (mg/kg MS)",
		"Cr VI-MS":"Chrome VI (Cr) (mg/kg MS)",
		"Cu-MS":"Cuivre (Cu) (mg/kg MS)",
		"Hg-MS":"Mercure (Hg) (mg/kg MS)",
		"Ni-MS":"Nickel (Ni) (mg/kg MS)",
		"Pb-MS":"Plomb (Pb) (mg/kg MS)",
		"Zn-MS":"Zinc (Zn) (mg/kg MS)",
		"C2":"Acide acétique (C2)",
		"C3":"Acide propionique (C3)",
		"iC4":"Acide isobutyrique (iC4)",
		"C4":"Acide butyrique (C4)",
		"iC5":"Acide isovalérique (iC5)",
		"C5":"Acide valérique (C5)",
		"C6":"Acide caproïque (C6)",
		"AGV":"AGV total",
		"TAC":"TAC",
		"FOS":"FOS",
		"FOSTAC":"FOSTAC",
		"DCO tot":"DCO totale",
		"DCO soluble":"DCO soluble",
		"Salmonelles-MS":"Salmonella (germes/g MS)",
		"Salmonelles-25gMS":"Salmonella (germes/25g MB)",
		"Salmonelles":"Salmonella (germes/g MB)",
		"Salmonelles num1":"Salmonella (germes/25g MB) n°1",
		"Salmonelles num2":"Salmonella (germes/25g MB) n°2",
		"Salmonelles num3":"Salmonella (germes/25g MB) n°3",
		"Salmonelles num4":"Salmonella (germes/25g MB) n°4",
		"Salmonelles num5":"Salmonella (germes/25g MB) n°5",
		"Salmonelles-10gMS":"Salmonella (germes/ 10g MS)",
		"Helminthes-MS":"Oeufs d'helminthes viables (germes/g MS)",
		"Helminthes-1.5g":"Oeufs d'helminthes viables (germes/1,5g MB)",
		"Helminthes-10gMS":"Oeufs d'helminthes viables (germes/ 10g MS)",
		"Germes-MS":"Germes butyriques",
		"Coliformes-MS":"Coliformes thermotolérants",
		"E.coli num1":"Escherichia coli (germes/g MB) n°1",
		"E.coli num2":"Escherichia coli (germes/g MB) n°2",
		"E.coli num3":"Escherichia coli (germes/g MB) n°3",
		"E.coli num4":"Escherichia coli (germes/g MB) n°4",
		"E.coli num5":"Escherichia coli (germes/g MB) n°5",
		"E.coli-MS":"Escherichia coli (germes/g MS)",
		"Enterocoques":"Entérocoques",
		"Spores-MS":"Spores d'anaérobies sulfito-réducteurs",
		"Enterovirus-10gMS":"Entérovirus",
		"Clostridium":"Clostridium perfringens",
		"Staphylocoques":"Staphylococcus aureus",
		"Listeria":"Listeria monocytogenes",
		"Nematodes larves":"Nematodes larves",
		"Nematodes oeufs":"Nematodes œufs",
		"Levures et moisissures":"Levures et moisissures",
		"Aspergillus":"Aspergillus",
		"Pythium":"Pythium",
		"C16H10":"Fluoranthène",
		"C20H12(b)":"Benzo(b)fluoranthène",
		"C20H12(a)":"Benzo(a)pyrène",
		"PCB 28":"PCB 28",
		"PCB 101":"PCB 101",
		"PCB 118":"PCB 118",
		"PCB 138":"PCB 138",
		"PCB 153":"PCB 153",
		"PCB 180":"PCB 180",
		"PCB 52":"PCB 52",
		"PCB tot":"PCB Total",
		"HAP16":"HAP16 totaux",
		"Inertes>2mm":"Inertes plastique/verre/métal > 2mm",
		"CEMAGREFF":"Composés internes méthode CEMAGREFF",
		"Azote Kjeldahl": "Azote Uréique",
		"Nitrite":"Nitrite",
		"Azote Uréique": "Azote Uréique",
		"Lgnine brute": "Lgnine brute",
		"Salmonelles-MS 25g":"Salmonelles-MS 25g",
		"Helminthes-1.5gMS":"Helminthes-1.5gMS",
		"Escherichia coli n°1":"Escherichia coli n°1",
		"Escherichia coli n°2":"Escherichia coli n°2",
		"Escherichia coli n°3":"Escherichia coli n°3",
		"Escherichia coli n°4":"Escherichia coli n°4",
		"Escherichia coli n°5":"Escherichia coli n°5",
		"Entérovirus":"Entérovirus",
		"Staphylococcus aureus":"Staphylococcus aureus",
		"Listeria monocytogenes":"Listeria monocytogenes",
		"Nematodes œufs":"Nematodes œufs",
		"Nombre jour pour atteindre 80%BMP":"Nombre jour pour atteindre 80%BMP",
		"Clostridium perfringens":"Clostridium perfringens",
		// "Nematodes larves":"Nematodes larves",
		"HAP16 totaux": "HAP16 totaux"
	}

	private analyseFieldLabelsShort = {
		'MS/MB' : { id: 'Matière sèche', label: 'Matière sèche sur MB' },
		'MO/MB' : { id: 'Matière organique', label: 'Matière organique sur MB' },
		'MO/MS' : { id: 'Matière volatile', label: 'Matière organique sur MS' },
		'MM/MS' : { id: 'Matière minérale sur MS', label: 'Matière minérale sur MS' },
		'MM/MB' : { id: 'Matière minérale sur MB', label: 'Matière minérale sur MB' },
		'pH' : { id: 'pH', label: 'pH' },
		'Conductivite' : { id: 'Conductivité', label: 'Conductivité' },
		'Red/Ox' : { id: 'Potentiel Red/Ox', label: 'Potentiel Red/Ox' },
		'Densite' : { id: 'Densité', label: 'Densité' },
		'PM/MB flash' : { id: 'Potentiel méthane sur MB (flash)', label: 'Potentiel méthane sur MB (flash)' },
		'PB/MB flash' : { id: 'Potentiel biogaz sur MB (flash)', label: 'Potentiel biogaz sur MB (flash)' },
		'PM/MO flash' : { id: 'Potentiel méthane sur MO (flash)', label: 'Potentiel méthane sur MO (flash)' },
		'PB/MO flash' : { id: 'Potentiel biogaz sur MO (flash)', label: 'Potentiel biogaz sur MO (flash)' },
		'%CH4 flash' : { id: 'Taux de CH4 - flash', label: 'Taux de CH4 - flash' },
		'PM/MB stand' : { id: 'Potentiel méthane sur MB', label: 'Potentiel méthane sur MB' },
		'PB/MB stand' : { id: 'Potentiel biogaz sur MB', label: 'Potentiel biogaz sur MB' },
		'PM/MO stand' : { id: 'Potentiel méthane sur MO', label: 'Potentiel méthane sur MO' },
		'PB/MO stand' : { id: 'Potentiel biogaz sur MO', label: 'Potentiel biogaz sur MO' },
		'PM/MS stand' : { id: 'Potentiel méthane sur MS', label: 'Potentiel méthane sur MS' },
		'%CH4 stand' : { id: 'Taux de CH4', label: 'Taux de CH4' },
		'nb jour pour 80%BMP' : { id: 'Nombre jour pour atteindre 80%BMP', label: 'Nombre jour pour atteindre 80%BMP' },
		'Norg': { id: 'Azote organique (N)', label: 'Azote organique (N)' },
		'Ntot' : { id: 'Azote totale (N)', label: 'Azote total (N)' },
		'N' : { id: 'Azote (N)', label: 'Azote (N)' },
		'Nkjeldahl' : { id: 'Azote Kjeldahl (N)', label: 'Azote Kjeldahl (N)' },
		'P2O5' : { id: 'Phosphore (P2O5)', label: 'Phosphore (P2O5)' },
		'K2O' : { id: 'Potassium (K2O)', label: 'Potassium (K2O)' },
		'C org.' : { id: 'Carbone organique', label: 'Carbone organique' },
		'C/N' : { id: 'C/N', label: 'C/N' },
		'N-NH4' : { id: 'Azote ammoniacal (N-NH4)', label: 'Azote ammoniacal (N-NH4)' },
		'N-NH3' : { id: 'Azote ammoniacal non-ionique (N-NH3)', label: 'Azote ammoniacal non-ionique (N-NH3)' },
		'N-NO3' : { id: 'Azote nitrique (N-NO3)', label: 'Azote nitrique (N-NO3)' },
		'N-NO2' : { id: 'Nitrite (NO2)', label: 'Nitrite (NO2)' },
		'Azote ureique' : { id: 'Azote uréique', label: 'Azote uréique' },
		'CaO' : { id: 'Calcium (CaO)', label: 'Calcium (CaO)' },
		'MgO' : { id: 'Magnésium (MgO)', label: 'Magnésium (MgO)' },
		'Na2O' : { id: 'Sodium (Na2O)', label: 'Sodium (Na2O)' },
		'SO3' : { id: 'Soufre (SO3)', label: 'Soufre (SO3)' },
		'Chlorures' : { id: 'Chlorures', label: 'Chlorures' },
		'CMC' : { id: 'CMC', label: 'CMC' },
		'CMN' : { id: 'CMN', label: 'CMN' },
		'ISMO' : { id: 'ISMO', label: 'ISMO' },
		'Cellulose brute' : { id: 'Cellulose brute', label: 'Cellulose brute' },
		'Fraction soluble' : { id: 'Fraction soluble', label: 'Fraction soluble' },
		'Hemicellulose brute' : { id: 'Hemicellulose brute', label: 'Hemicellulose brute' },
		'Lignine brute' : { id: 'Lgnine brute', label: 'Lgnine brute' },
		'Cendre totale' : { id: 'Cendre totale', label: 'Cendre totale' },
		'Digestibilite de la MO' : { id: 'Digestibilité de la MO', label: 'Digestibilité de la MO' },
		'Extractif non azoté réel' : { id: 'Extractif non azoté réel', label: 'Extractif non azoté réel' },
		'Matiere grasse brute' : { id: 'Matière grasse brute', label: 'Matière grasse brute' },
		'Matiere organique indigeree' : { id: 'Matière organique indigérée', label: 'Matière organique indigérée' },
		'Proteine brute' : { id: 'Protéine brute', label: 'Protéine brute' },
		'Proteine brute reelle' : { id: 'Protéine brute réelle', label: 'Protéine brute réelle' },
		'Glucide brut' : { id: 'Glucide brut', label: 'Glucide brut' },
		'B' : { id: 'Bore (B)', label: 'Bore (B)' },
		'Al' : { id: 'Aluminium (Al)', label: 'Aluminium (Al)' },
		'Cl' : { id: 'Chlore (Cl)', label: 'Chlore (Cl)' },
		'Si' : { id: 'Silicium (Si)', label: 'Silicium (Si)' },
		'Fe' : { id: 'Fer (Fe)', label: 'Fer (Fe)' },
		'Mn' : { id: 'Manganèse (Mn)', label: 'Manganèse (Mn)' },
		'Mo' : { id: 'Molybdène (Mo)', label: 'Molybdène (Mo)' },
		'Co' : { id: 'Cobalt (Co)', label: 'Cobalt (Co)' },
		'Cd' : { id: 'Cadmium (Cd)', label: 'Cadmium (Cd)' },
		'Cr' : { id: 'Chrome total (Cr)', label: 'Chrome total (Cr)' },
		'Cu' : { id: 'Cuivre (Cu)', label: 'Cuivre (Cu)' },
		'Hg' : { id: 'Mercure (Hg)', label: 'Mercure (Hg)' },
		'Ni' : { id: 'Nickel (Ni)', label: 'Nickel (Ni)' },
		'Pb' : { id: 'Plomb (Pb)', label: 'Plomb (Pb)' },
		'Zn' : { id: 'Zinc (Zn)', label: 'Zinc (Zn)' },
		'P' : { id: 'Phosphore (P)', label: 'Phosphore (P)' },
		'K' : { id: 'Potassium (K)', label: 'Potassium (K)' },
		'Ca' : { id: 'Calcium (Ca)', label: 'Calcium (Ca)' },
		'Mg' : { id: 'Magnésium (Mg)', label: 'Magnésium (Mg)' },
		'Na' : { id: 'Sodium (Na)', label: 'Sodium (Na)' },
		'S' : { id: 'Soufre (S)', label: 'Soufre (S)' },
		'Se' : { id: 'Selenium (Se) /MS', label: 'Selenium (Se) /MS' },
		'As' : { id: 'Arsenic (As) /MS', label: 'Arsenic (As) /MS' },
		'Cr+Cu+Ni+Zn' : { id: 'Cr+Cu+Ni+Zn /MS', label: 'Cr+Cu+Ni+Zn /MS' },
		'Cd-MS' : { id: 'Cadmium (Cd) /MS', label: 'Cadmium (Cd) /MS' },
		'Cr-MS' : { id: 'Chrome total (Cr) /MS', label: 'Chrome total (Cr) /MS' },
		'Cr VI-MS' : { id: 'Chrome VI (CrVI) MS', label: 'Chrome VI (CrVI) MS' },
		'Cu-MS' : { id: 'Cuivre (Cu) /MS', label: 'Cuivre (Cu) /MS' },
		'Hg-MS' : { id: 'Mercure (Hg) /MS', label: 'Mercure (Hg) /MS' },
		'Ni-MS' : { id: 'Nickel (Ni) /MS', label: 'Nickel (Ni) /MS' },
		'Pb-MS' : { id: 'Plomb (Pb) /MS', label: 'Plomb (Pb) /MS' },
		'Zn-MS' : { id: 'Zinc (Zn) /MS', label: 'Zinc (Zn) /MS' },
		'C2' : { id: 'Acide acétique (C2)', label: 'Acide acétique (C2)' },
		'C3' : { id: 'Acide propionique (C3)', label: 'Acide propionique (C3)' },
		'iC4' : { id: 'Acide isobutyrique (iC4)', label: 'Acide isobutyrique (iC4)' },
		'C4' : { id: 'Acide butyrique (C4)', label: 'Acide butyrique (C4)' },
		'iC5' : { id: 'Acide isovalérique (iC5)', label: 'Acide isovalérique (iC5)' },
		'C5' : { id: 'Acide valérique (C5)', label: 'Acide valérique (C5)' },
		'C6' : { id: 'Acide caproïque (C6)', label: 'Acide caproïque (C6)' },
		'AGV' : { id: 'AGV total', label: 'AGV total' },
		'TAC' : { id: 'TAC', label: 'TAC' },
		'FOS' : { id: 'FOS', label: 'FOS' },
		'FOSTAC' : { id: 'FOSTAC', label: 'FOSTAC' },
		'DCO tot' : { id: 'DCO tot', label: 'DCO tot' },
		'DCO soluble' : { id: 'DCO soluble', label: 'DCO soluble' },
		'Salmonelles-MS' : { id: 'Salmonelles-MS', label: 'Salmonelles-MS' },
		'Salmonelles-25gMS' : { id: 'Salmonelles-MS 25g', label: 'Salmonelles-MS 25g' },
		'Salmonelles' : { id: 'Salmonelles', label: 'Salmonelles' },
		'Salmonelles num1' : { id: 'Salmonelles n°1', label: 'Salmonelles n°1' },
		'Salmonelles num2' : { id: 'Salmonelles n°2', label: 'Salmonelles n°2' },
		'Salmonelles num3' : { id: 'Salmonelles n°3', label: 'Salmonelles n°3' },
		'Salmonelles num4' : { id: 'Salmonelles n°4', label: 'Salmonelles n°4' },
		'Salmonelles num5' : { id: 'Salmonelles n°5', label: 'Salmonelles n°5' },
		'Salmonelles-10gMS' : { id: 'Salmonelles-10gMS', label: 'Salmonelles-10gMS' },
		'Helminthes-MS' : { id: 'Helminthes-MS', label: 'Helminthes-MS' },
		'Helminthes-1.5g' : { id: 'Helminthes-1.5gMS', label: 'Helminthes-1.5gMS' },
		'Helminthes-10gMS' : { id: 'Helminthes-10gMS', label: 'Helminthes-10gMS' },
		'Germes-MS' : { id: 'Germes butyriques', label: 'Germes butyriques' },
		'Coliformes-MS' : { id: 'Coliformes thermotolérants', label: 'Coliformes thermotolérants' },
		'E.coli num1' : { id: 'Escherichia coli n°1', label: 'Escherichia coli n°1' },
		'E.coli num2' : { id: 'Escherichia coli n°2', label: 'Escherichia coli n°2' },
		'E.coli num3' : { id: 'Escherichia coli n°3', label: 'Escherichia coli n°3' },
		'E.coli num4' : { id: 'Escherichia coli n°4', label: 'Escherichia coli n°4' },
		'E.coli num5' : { id: 'Escherichia coli n°5', label: 'Escherichia coli n°5' },
		'E.coli-MS' : { id: 'Escherichia coli', label: 'Escherichia coli' },
		'Enterocoques' : { id: 'Entérocoques', label: 'Entérocoques' },
		'Spores-MS' : { id: 'Spores d\'anaérobies SR', label: 'Spores d\'anaérobies SR' },
		'Enterovirus-10gMS' : { id: 'Entérovirus', label: 'Entérovirus' },
		'Clostridium' : { id: 'Clostridium perfringens', label: 'Clostridium perfringens' },
		'Staphylocoques' : { id: 'Staphylococcus aureus', label: 'Staphylococcus aureus' },
		'Listeria' : { id: 'Listeria monocytogenes', label: 'Listeria monocytogenes' },
		'Nematodes larves' : { id: 'Nematodes larves', label: 'Nematodes larves' },
		'Nematodes oeufs' : { id: 'Nematodes œufs', label: 'Nematodes œufs' },
		'Levures et moisissures' : { id: 'Levures et moisissures', label: 'Levures et moisissures' },
		'Aspergillus' : { id: 'Aspergillus', label: 'Aspergillus' },
		'Pythium' : { id: 'Pythium', label: 'Pythium' },
		'C16H10' : { id: 'Fluoranthène', label: 'Fluoranthène' },
		'C20H12(b)' : { id: 'Benzo(b)fluoranthène', label: 'Benzo(b)fluoranthène' },
		'C20H12(a)' : { id: 'Benzo(a)pyrène', label: 'Benzo(a)pyrène' },
		'PCB 28' : { id: 'PCB 28', label: 'PCB 28' },
		'PCB 101' : { id: 'PCB 101', label: 'PCB 101' },
		'PCB 118' : { id: 'PCB 118', label: 'PCB 118' },
		'PCB 138' : { id: 'PCB 138', label: 'PCB 138' },
		'PCB 153' : { id: 'PCB 153', label: 'PCB 153' },
		'PCB 180' : { id: 'PCB 180', label: 'PCB 180' },
		'PCB 52' : { id: 'PCB 52', label: 'PCB 52' },
		'PCB tot' : { id: 'PCB Total', label: 'PCB Total' },
		'HAP16' : { id: 'HAP16 totaux', label: 'HAP16 totaux' },
		'Inertes>2mm' : { id: 'Inertes plastique/verre/métal > 2mm', label: 'Inertes plastique/verre/métal > 2mm' },
		'CEMAGREFF' : { id: 'Composés internes méthode CEMAGREFF', label: 'Composés internes méthode CEMAGREFF' }
	}

	private paysEurope = [
		'Allemagne',          'Autriche',
		'Belgique',           'Bulgarie',
		'Chypre',             'Croatie',
		'Danemark',           'Espagne',
		'Estonie',            'Finlande',
		'France',             'Grèce',
		'Hongrie',            'Irlande',
		'Italie',             'Lettonie',
		'Lituanie',           'Luxembourg',
		'Malte',              'Pays-Bas',
		'Pologne',            'Portugal',
		'République tchèque', 'Roumanie',
		'Slovaquie',          'Slovénie',
		'Suède'
	]

	private pays = [
		"Afghanistan",
		"Albania",
		"Algeria",
		"Andorra",
		"Angola",
		"Antigua and Barbuda",
		"Argentina",
		"Armenia",
		"Australia",
		"Austria",
		"Azerbaijan",
		"Bahamas",
		"Bahrain",
		"Bangladesh",
		"Barbados",
		"Belarus",
		"Belgique",
		"Belize",
		"Benin",
		"Bhutan",
		"Bolivia",
		"Bosnia and Herzegovina",
		"Botswana",
		"Brazil",
		"Brunei",
		"Bulgaria",
		"Burkina Faso",
		"Burundi",
		"Cabo Verde",
		"Cambodia",
		"Cameroon",
		"Canada",
		"Central African Republic (CAR)",
		"Chad",
		"Chile",
		"China",
		"Colombia",
		"Comoros",
		"Costa Rica",
		"Cote d'Ivoire",
		"Croatia",
		"Cuba",
		"Cyprus",
		"Czech Republic",
		"Democratic Republic of the Congo",
		"Denmark",
		"Djibouti",
		"Dominica",
		"Dominican Republic",
		"Ecuador",
		"Egypt",
		"El Salvador",
		"Equatorial Guinea",
		"Eritrea",
		"Estonia",
		"Ethiopia",
		"Fiji",
		"Finland",
		"France",
		"Gabon",
		"Gambia",
		"Georgia",
		"Germany",
		"Ghana",
		"Greece",
		"Grenada",
		"Guatemala",
		"Guinea",
		"Guinea-Bissau",
		"Guyana",
		"Haiti",
		"Honduras",
		"Hungary",
		"Iceland",
		"India",
		"Indonesia",
		"Iran",
		"Iraq",
		"Ireland",
		"Israel",
		"Italy",
		"Jamaica",
		"Japan",
		"Jordan",
		"Kazakhstan",
		"Kenya",
		"Kiribati",
		"Kosovo",
		"Kuwait",
		"Kyrgyzstan",
		"Laos",
		"Latvia",
		"Lebanon",
		"Lesotho",
		"Liberia",
		"Libya",
		"Liechtenstein",
		"Lithuania",
		"Luxembourg",
		"Macedonia (FYROM)",
		"Madagascar",
		"Malawi",
		"Malaysia",
		"Maldives",
		"Mali",
		"Malta",
		"Marshall Islands",
		"Mauritania",
		"Mauritius",
		"Mexico",
		"Micronesia",
		"Moldova",
		"Monaco",
		"Mongolia",
		"Montenegro",
		"Morocco",
		"Mozambique",
		"Myanmar (Burma)",
		"Namibia",
		"Nauru",
		"Nepal",
		"Netherlands",
		"New Zealand",
		"Nicaragua",
		"Niger",
		"Nigeria",
		"North Korea",
		"Norway",
		"Oman",
		"Pakistan",
		"Palau",
		"Palestine",
		"Panama",
		"Papua New Guinea",
		"Paraguay",
		"Peru",
		"Philippines",
		"Poland",
		"Portugal",
		"Qatar",
		"Republic of the Congo",
		"Romania",
		"Russia",
		"Rwanda",
		"Saint Kitts and Nevis",
		"Saint Lucia",
		"Saint Vincent and the Grenadines",
		"Samoa",
		"San Marino",
		"Sao Tome and Principe",
		"Saudi Arabia",
		"Senegal",
		"Serbia",
		"Seychelles",
		"Sierra Leone",
		"Singapore",
		"Slovakia",
		"Slovenia",
		"Solomon Islands",
		"Somalia",
		"South Africa",
		"South Korea",
		"South Sudan",
		"Spain",
		"Sri Lanka",
		"Sudan",
		"Suriname",
		"Swaziland",
		"Sweden",
		"Switzerland",
		"Syria",
		"Taiwan",
		"Tajikistan",
		"Tanzania",
		"Thailand",
		"Timor-Leste",
		"Togo",
		"Tonga",
		"Trinidad and Tobago",
		"Tunisia",
		"Turkey",
		"Turkmenistan",
		"Tuvalu",
		"Uganda",
		"Ukraine",
		"United Arab Emirates (UAE)",
		"United Kingdom (UK)",
		"United States of America (USA)",
		"Uruguay",
		"Uzbekistan",
		"Vanuatu",
		"Vatican City (Holy See)",
		"VenezuelaA193",
		"Vietnam",
		"Yemen",
		"Zambia",
		"Zimbabwe"
	]


}
