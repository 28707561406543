import { TranslateService } from '@ngx-translate/core';
import { Evenement } from 'journal/models/dto/evenement';
import { ToastMessageService } from 'global/services/toast-message/toast-message.service';
import { VariableService } from 'rondes/services/variable/variable.service';
import { TacheService } from 'journal/services/tache.service';
import { Tache } from 'journal/models/dto/tache';
import { EvenementService } from 'journal/services/evenement.service';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { LoginService } from 'global/services/login/login.service';
import { ParametresService } from 'global/services/parametres/parametres.service';
import { GisementsService } from 'tracabilite/services/gisements/gisements.service';
import { TiersService } from 'tracabilite/services/tiers/tiers.service';
import { StockIntrantService } from 'intrants/services/stock-intrant/stock-intrant.service';
import { ReceptionService } from 'appro/services/reception/reception.service';
import { ReceptionPrevueService } from 'appro/services/reception-prevue/reception-prevue.service';
import { BreadcrumbService } from 'global/services/breadcrumb/breadcrumb.service';
import { Reception } from 'appro/models/dto/reception';
import { OuvragesIncorporationService } from 'shared-ration/services/ouvrages-incorporation/ouvrages-incorporation.service';
import {DigesteurService} from "shared-ration/services/ouvrages-digestion/digesteur.service";

@Component({
    selector: 'event-modal',
    templateUrl: './event-modal.component.html',
    styleUrls: ['./event-modal.component.scss']
})
export class EventModalComponent implements OnInit {

    @Input() eventType: string = 'reception';
    @Input() event: any = {};
    @Input() date: any;
    toSave: boolean = false;
    readOnly: boolean;
    parametres: any;
    evenement: Evenement;
    tache: Tache;
    variables;
    gisements: any[] = [];
    transporteurs: any[] = [];
    destinations: any[] = [];
    destination: any;
    stocks: any[] = [];
    numReceptionExists: boolean;
    eventTitle: string;
    repetition;
    limiteRep;
    loading = false;
    update = false;
    loadingMessage;

    constructor(
        private loginService: LoginService,
        private parametresService: ParametresService,
        private activeModal: NgbActiveModal,
        private tiersService: TiersService,
        private gisementsService: GisementsService,
        private stockService: StockIntrantService,
        private receptionService: ReceptionService,
        private receptionPrevueService: ReceptionPrevueService,
        private ref: ChangeDetectorRef,
        private router: Router,
        private datePipe: DatePipe,
        private breadcrumbService: BreadcrumbService,
        private evenementService: EvenementService,
        private tacheService: TacheService,
        private variableService: VariableService,
        private toastMessageService: ToastMessageService,
        private translateService: TranslateService,
        private digesteurService: DigesteurService,
        private ouvragesIncService: OuvragesIncorporationService
    ) {
        this.translateService.onLangChange.subscribe(lang => {
           this.setTitle();
        });
    }

    async ngOnInit() {
        this.loginService.getWriteAccessRight().then(accessRight => this.readOnly = !accessRight);
        const perimetre = JSON.parse(sessionStorage.getItem('perimetre'));
        const user = sessionStorage.getItem('user');
        this.parametres = this.parametresService.getParametres();
        this.loadingMessage = this.translateService.instant('app.global.chargement');
        if (this.eventType === 'evenement') {
            this.evenement = new Evenement();
        } else if (this.eventType === 'tache') {
            this.tache = new Tache();
        }
        if (this.router.url.includes('ronde')) {
            this.variableService.bySite(perimetre.id, 0, 10000).then(res => {
                this.variables = res.filter(r => {
                    if (r.modifiedBy && r.modifiedBy === user) {
                        return r;
                    }
                });
                if (this.variables.length === 0) {
                    this.variables = res.filter(r => {
                        if (r.createdBy && r.createdBy === user) {
                            return r;
                        }
                    });
                }
                if (this.variables.length > 0) {
                    this.variables.sort((a, b) => b.modifiedOn - a.modifiedOn);
                    if (this.variables[0]) {
                        const nom = this.variables[0].nom;
                        const description = `${nom} = ${this.variables[0].valeurParDefaut}`;
                        if (this.tache) {
                            this.tache.nom = nom;
                            this.tache.description = description;
                        } else if (this.evenement) {
                            this.evenement.nom = nom;
                            this.evenement.description = description;
                        }

                    }
                }
            }).catch(err => this.toastMessageService.add('DANGER', this.translateService.instant('app.global.messages.problemesLectureDonnees')));
            this.loading = false;
        } else {
            if (!this.event._id) {
                this.setDefaultValue();
            } else if(this.eventType == 'receptionPrevue') {
                this.gisementChanged(this.event);
            }
        }
        this.setDate();
        this.setTitle();
        if (perimetre && perimetre.dataType === 'site') {
            this.loadData(perimetre);
        } else if (this.event.site) {
            this.loadData(this.event.site);
        }
        if (this.event._id) {
            this.update = true;
        }
        this.loading = false;
    }

    getEvenement(event) {
        this.evenement = event;
    }

    getTache(event) {
        this.tache = event;
    }

    toClose(event) {
        if (event) {
            this.activeModal.close(event);
        }
    }

    async siteChanged() {
        this.loadData(this.event.site);
        this.ref.detectChanges();
    }

    close() {
        this.activeModal.dismiss('dismiss');
    }

    save(save: boolean) {
        if (save) {
            if (this.eventType === 'tache') {
                this.toSave = true;
            } else {
            this.saveData()
                .then(event => {
                    this.activeModal.close(event);
                });
            }
        }

    }

    moreInfo() {
        this.saveData()
            .then(event => {
                this.activeModal.close(event);
                this.router.navigate([this.toMoreInfo(event)]);
            })
    }

    onReceptionBlur() {
        this.receptionService.checkIfExists(this.event)
            .then(res => this.numReceptionExists = res);
    }

    onReceptionPrevueBlur() {
        this.receptionPrevueService.checkIfExists(this.event)
            .then(res => this.numReceptionExists = res);
    }

    transform() {
        let recep = new Reception();
        _.merge(recep, this.event);
        recep.dateReception = recep.dateReception.getTime();
        delete recep._id;
        delete recep._rev;
        delete recep.dataType;
        sessionStorage.setItem('receptionTmp', JSON.stringify(recep));
        this.breadcrumbService.removeFrom(-1);
        this.activeModal.close(event);
        this.router.navigate(['appro/reception/add'], { queryParams: { 'receptionPrevue': this.event._id } })
    }

    gisementChanged(event){
        if(this.event.gisement && this.event.gisement.ouvrageIncorporation) {
          this.destination = this.event.gisement.ouvrageIncorporation;
        }else if(this.event.gisement && this.event.gisement.stockIntrant) {
          this.destination = this.event.gisement.stockIntrant;
        }
    }

    private loadData(perimetre) {
        this.gisements = [];
        this.stocks = [];
        this.transporteurs = [];
        if (this.eventType !== 'evenement' && this.eventType !== 'tache') {
            this.gisementsService.bySite(perimetre.id)
                .then(res => {
                    this.gisements = res.filter(gisement => !gisement.notDisplayInDropdowns);
                    if (this.event && this.event.gisement)
                        this.gisements = _.uniqWith(_.concat(this.gisements, [this.event.gisement]),
                            (arrValue, othValue) => arrValue._id == othValue._id && arrValue.nom == othValue.nom
                        );
                    this.event.gisement = !this.event._id && this.eventType === 'receptionPrevue' ? this.gisements[0] : this.event.gisement;
                })
            this.tiersService.bySite(perimetre.id)
                .then(res => {
                    if (this.event.gisement && this.event.gisement.transporteur)
                        res = _.uniqBy(_.concat(res, [this.event.gisement.transporteur]), '_id');
                    if (res)
                        this.transporteurs = res.filter(t => t.typeActivite && t.typeActivite.includes('Transporteur'));
                })
            this.stockService.bySite(perimetre.id)
                .then(async res => {
                    this.stocks = res;
                    if (this.event && this.event.gisement && this.event.gisement.stockIntrant)
                        this.stocks = _.uniqWith(_.concat(this.stocks, [this.event.gisement.stockIntrant]),
                            (arrValue, othValue) => arrValue._id == othValue._id && arrValue.nom == othValue.nom
                        );
                    this.destinations = [];
                    if(this.stocks && this.stocks.length){
                        this.destinations = this.destinations.concat({
                          "label": this.translateService.instant('app.global.termes.stockIntrants'),
                          "addLink": "ration2/stock-intrant/add",
                          "options": this.stocks.filter(s => !s.notDisplayInDropdowns)
                        })
                    }
                    let ouvrages = await this.ouvragesIncService.getOuvragesBySite(perimetre.id).toPromise() as any[];
                    const ouvrageIsPresent = this.destinations.find(d => d.label.toLocaleLowerCase().includes('ouvrage'));
                    if(ouvrages && ouvrages.length && !ouvrageIsPresent){
                        ouvrages = ouvrages.filter(ouv => !ouv.directDigesteur).map(ouv => {
                          ouv._id = ouv.id;
                          ouv._rev = ouv.revision;
                          return ouv;
                        });
                        this.destinations = this.destinations.concat({
                          "label": this.translateService.instant('app.global.termes.ouvrageIncorporation'),
                          "addLink": "ration2/ouvrages-incorporation/add",
                          "options": ouvrages
                        })
                    }
                    let digesteurs = await this.digesteurService.getDigesteursBySite(perimetre.id).toPromise() as any[];
                    const digesteurIsPresent = this.destinations.find(d => d.label.toLocaleLowerCase().includes('digesteur'));
                    if(digesteurs && digesteurs.length && !digesteurIsPresent){
                        digesteurs = digesteurs.map(ouv => {
                            ouv._id = ouv.id;
                            ouv._rev = ouv.revision;
                            ouv.nom = ouv.nom;
                            ouv.dataType = "digesteur";
                            return ouv;
                        });
                        this.destinations = this.destinations.concat({
                            "label": "Digesteurs",
                            "addLink": "ration2/ouvrages-digestion/add",
                            "options": digesteurs
                        })
                    }
                })
        }
    }

    private setDefaultValue() {
        switch (this.eventType) {
            case 'receptionPrevue':
                this.event.numReception = this.datePipe.transform(this.date, "yyyyMMdd-HHmmss");
                this.event.gisement = {};
                this.event.statutFacturationClient = "En attente";
                this.event.statutFacturationFournisseur = "En attente";
                break;
            default:
                this.event.statutReception = "Acceptée";
                this.event.numReception = this.datePipe.transform(this.date, "yyyyMMdd-HHmmss");
                this.event.statutFacturationClient = "En attente";
                this.event.statutFacturationFournisseur = "En attente";
                break;
        }
    }

    private setDate() {
        if (this.date && this.eventType !== 'evenement' && this.eventType !== 'tache') {
            switch (this.eventType) {
                case 'receptionPrevue':
                    this.event.dateReception = this.date;
                    this.event.dateReception.setHours(12, 0, 0);
                    break;
                default:
                    this.event.dateReception = this.date;
                    break;
            }
        }
    }

    private saveData() {
        let help;
        switch (this.eventType) {
            case 'receptionPrevue':
                this.event.dateReception = this.event.dateReception.getTime();
                if (this.event.gisement.site) {
                    delete this.event.gisement.site;
                }
                if (this.event.gisement.transporteur.site){
                    delete this.event.gisement.transporteur.site;
                }
                this.setDestination();
                help = this.receptionPrevueService.upSert(this.event);
                break;
            case 'evenement':
                this.evenement.dateDebut = this.evenement.dateDebut.getTime();
                this.evenement.dateFin = this.evenement.dateFin.getTime();
                help = this.evenementService.upSert(this.evenement);
                break;
            case 'tache':
                this.tache.dateDebut = this.tache.dateDebut.getTime();
                this.tache.dateFin = this.tache.dateFin.getTime();
                help = this.tacheService.upSert(this.tache);
                break;
            default:
                this.event.dateReception = this.event.dateReception.getTime();
                if (this.event.gisement.site) {
                    delete this.event.gisement.site;
                }
                if (this.event.gisement.transporteur.site) {
                    delete this.event.gisement.transporteur.site;
                }
                help = this.receptionService.upSert(this.event);
                break;
        }
        return help;
    }

    private toMoreInfo(event) {
        let help
        switch (this.eventType) {
            case 'receptionPrevue':
                help = 'appro/planning/' + event.id;
                break;
            case 'evenement':
                help = 'journal/evenements/' + event.id;
                break;
            case 'tache':
                help = 'journal/taches/' + event.id;
                break;
            default:
                help = 'appro/reception/' + event.id;
                break;
        }
        return help;
    }

    private setTitle() {
        switch (this.eventType) {
            case 'receptionPrevue':
                this.eventTitle = this.translateService.instant('app.modules.global.components.calendrier.event-modal.receptionPrevue');
                break;
            case 'evenement':
                this.eventTitle = this.translateService.instant('app.modules.global.components.calendrier.event-modal.evenement');
                break;
            case 'tache':
                this.eventTitle = this.translateService.instant('app.modules.global.components.calendrier.event-modal.tache');
                break;
            default:
                this.eventTitle = this.translateService.instant('app.modules.global.components.calendrier.event-modal.reception');
                break;
        }
    }

    private setDestination(){
        if(this.destination.dataType == 'stockIntrant') {
          this.event.gisement.stockIntrant = this.destination;
          this.event.gisement.ouvrageIncorporation = undefined;
          this.event.gisement.digesteur = undefined;
        } else if(this.destination.dataType == 'ouvrageIncorporation'){
          this.event.gisement.ouvrageIncorporation = this.destination;
          this.event.gisement.stockIntrant = undefined;
          this.event.gisement.digesteur = undefined;
        }
    }

}
